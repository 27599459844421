import { BaseDto } from "@src/dto/base.dto";
import type {
  GeographicScope,
  Levels,
  ProductStatus,
} from "@utils/data/enums/generals.enum";

import type { ProductPutRequest } from "types/api/product";

export class ProductPutRequestDto extends BaseDto {
  name: string;
  level: Levels;
  status: ProductStatus;
  numberOfSkus: number;
  mopOwner?: string;
  productOwner: string;
  digitalOwner: string;
  imageModelShotOwner: string;
  imageProductOwner: string;
  geographicScope: GeographicScope;
  elementToHighlight: string;
  benefit?: string;
  sellOutAmbition: string;
  assortmentFile?: File;
  additionalPhotos?: File[];
  newPackaging?: boolean;
  packshots?: File[];

  constructor(product: ProductPutRequest) {
    super();
    this.name = product.name;
    this.level = product.level;
    this.status = product.status;
    this.numberOfSkus = product.numberOfSkus;
    this.mopOwner = product.mopOwner;
    this.productOwner = product.productOwner;
    this.digitalOwner = product.digitalOwner;
    this.imageModelShotOwner = product.imageModelShotOwner;
    this.imageProductOwner = product.imageProductOwner;
    this.geographicScope = product.geographicScope;
    this.elementToHighlight = product.elementToHighlight;
    this.benefit = product.benefit;
    this.sellOutAmbition = product.sellOutAmbition;
    this.newPackaging = product.newPackaging;
    if (product.assortmentFile) {
      this.assortmentFile = product.assortmentFile as File;
    }
    if (product.additionalPhotos) {
      this.additionalPhotos = product.additionalPhotos as File[];
    }
    if (product.packshots) {
      this.packshots = product.packshots as File[];
    }
  }
}
