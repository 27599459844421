import { defaultApi } from "store/api/api";
import endpoints from "store/api/endpoints";
import type {
  CategoriesDetails,
  CategoriesDetailsRequest,
  CategoryRequest,
} from "types/api/category";

export const categoriesApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<CategoriesDetails[], void>({
      query: () => ({
        url: endpoints.category.all,
      }),
    }),
    getCategory: build.query<CategoriesDetails[], CategoryRequest>({
      query: ({ id }) => ({
        url: endpoints.category.withId(id),
      }),
    }),
    getCategoryWithAxis: build.query<
      CategoriesDetails[],
      CategoriesDetailsRequest
    >({
      query: (params) => ({
        url: endpoints.category.all,
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetCategoriesQuery, useGetCategoryWithAxisQuery } =
  categoriesApi;
