import { createSlice } from "@reduxjs/toolkit";
import { categoriesApi } from "@store/api/categories";

import type { CategoriesDetails } from "types/api/category";

interface CategoryState {
  categoryType: CategoriesDetails[];
}

const initialState: CategoryState = {
  categoryType: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoryType: (state, { payload }: { payload: CategoriesDetails[] }) => {
      state.categoryType = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      categoriesApi.endpoints.getCategories.matchFulfilled,
      (state, { payload }) => {
        state.categoryType = payload;
      },
    );
  },
});

export default categorySlice.reducer;
