import { styled } from "@mui/material/styles";

export const StyledDropZone = styled("div", {
  shouldForwardProp: (prop) => prop !== "isError" && prop !== "isHovered",
})<{ isError?: boolean; isHovered?: boolean }>(
  ({ theme, isError, isHovered }) => ({
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    background: isHovered
      ? theme.palette.primary.light
      : theme.palette.background.default,
    border: `1px dotted ${isError ? theme.palette.error.main : theme.palette.primary.main}`,
  }),
);

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
