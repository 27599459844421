import React, { type FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import Carousel from "@components/Carousel/Carousel";
import FileUploader from "@components/FileUploader/FileUploader";
import GeographicScopeSelector from "@components/Inputs/GeographicScopeSelector";
import ProductActions from "@components/ProductActions";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import HighlightedFields from "@pages/ProductPage/components/ProductPageHeader/HighlightedFields";
import OwnersTab from "@pages/ProductPage/components/ProductPageHeader/OwnersTab";
import useNestedIntl from "@src/hooks/useNestedIntl";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type { ProductPutForm, SeasonsGlobalInformations } from "@src/types";
import { ACCEPT_FILES_TYPES, Authority, MODAL_ACTION_TYPES } from "@src/types";
import {
  useDeleteAdditionalPhotosProductMutation,
  useDeletePackshotProductMutation,
  usePutProductMutation,
} from "@store/api/product";
import { selectCategoryType } from "@store/category/category.selector";
import { useAppSelector } from "@store/hooks";
import {
  selectCanModifySeasonData,
  selectSeasons,
} from "@store/season/season.selector";
import type { RootState } from "@store/store";
import {
  DEBOUNCE_DELAY,
  LevelOfActivationOption,
} from "@utils/constants.utils";
import { GeographicScope } from "@utils/data/enums/generals.enum";

import type { CategoriesDetails } from "types/api/category";
import type { ProductGetResponse } from "types/api/product";
import type { SubCategoriesDetails } from "types/api/subCategory";
import { useDebounce } from "use-debounce";

interface ProductHeaderProps {
  product: ProductGetResponse;
}

const ProductPageHeaderData: FC<ProductHeaderProps> = ({ product }) => {
  const intl = useIntl();
  const nestedIntl = useNestedIntl();
  const navigate = useNavigate();

  const [updateProduct, { isSuccess, isError }] = usePutProductMutation();

  const seasons = useAppSelector(selectSeasons);
  const categories = useAppSelector(selectCategoryType);
  const canUpdateProduct = useAppSelector((state: RootState) =>
    selectCanModifySeasonData(state, Authority.UPDATE_PRODUCT_BRIEF),
  );

  const [deletePackshot] = useDeletePackshotProductMutation();
  const [deleleteAdditionalPhotos] = useDeleteAdditionalPhotosProductMutation();

  const [season, setSeason] = useState<SeasonsGlobalInformations>();
  const [category, setCategory] = useState<CategoriesDetails | undefined>(
    undefined,
  );
  const [subCategory, setSubCategory] = useState<
    SubCategoriesDetails | undefined
  >(undefined);

  const {
    register,
    setValue,
    watch,
    control,
    reset,
    formState: { isDirty },
  } = useForm<ProductPutForm>();

  const formValues = watch();

  const [debouncedFormValues] = useDebounce(formValues, DEBOUNCE_DELAY);

  const { actionType } = usePopupHandler();

  useEffect(() => {
    categories.map((categoryItem) => {
      const subCategory = categoryItem.subCategories.find(
        (subCategoryItem) => subCategoryItem.id === product.subCategoryId,
      );
      if (subCategory) {
        setCategory(categoryItem);
        setSubCategory(subCategory);
      }
    });
    seasons.map((yearItem) => {
      yearItem.seasons.map((seasonItem) => {
        const season = seasonItem.elements.find(
          (element) => element.id === product.seasonId,
        );
        if (season) {
          setSeason(season);
        }
      });
    });
  }, [categories, seasons]);

  useEffect(() => {
    if (season) {
      if (actionType === MODAL_ACTION_TYPES.DELETE_PRODUCT) {
        const redirectWithParams = generatePath("/season/:cleanName", {
          cleanName: season.cleanName,
        });
        navigate(redirectWithParams);
      }
    }
  }, []);

  const handleDeleteAdditionalPhoto = (index: number) => {
    if (formValues.additionalPhotos) {
      deleleteAdditionalPhotos({
        name: formValues.additionalPhotos[index].name,
        productId: product.id,
      });
    }
  };

  const handleDeletePackshot = (index: number) => {
    if (formValues.packshots) {
      deletePackshot({
        name: formValues.packshots[index].name,
        productId: product.id,
      });
    }
  };

  // 1. auto submit data when form data changes
  useEffect(() => {
    if (isDirty) {
      onSubmit();
    }
  }, [debouncedFormValues]);

  // 2. backend update request
  const onSubmit = async () => {
    if (season) {
      updateProduct({
        ...product,
        ...formValues,
        additionalPhotos: formValues.additionalPhotos?.filter(
          (additionalPhoto) => additionalPhoto instanceof File,
        ) as File[],
        packshots: formValues.packshots?.filter(
          (packshot) => packshot instanceof File,
        ) as File[],
        seasonName: season.name,
      });
    }
  };

  // 3. rebase form values on backend response
  useEffect(() => {
    reset({
      geographicScope: product.geographicScope,
      name: product.name,
      numberOfSkus: product.numberOfSkus,
      sellOutAmbition: product.sellOutAmbition,
      level: product.level,
      assortmentFile: product.assortmentFile,
      packshots: product.packshots,
      additionalPhotos: product.additionalPhotos,
      newPackaging: product.newPackaging,
      elementToHighlight: product.elementToHighlight,
      mopOwner: product.mopOwner,
      digitalOwner: product.digitalOwner,
      productOwner: product.productOwner,
      imageProductOwner: product.imageProductOwner,
      imageModelShotOwner: product.imageModelShotOwner,
    });
  }, [product, isSuccess, isError]);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const _renderTab = () => {
    if (tabValue === 0) {
      return (
        <Grid container item display="flex" flexDirection="row" gap={4}>
          <Grid item>
            <FormLabel>
              <FormattedMessage id="form.label.assortmentFile" />
            </FormLabel>
            <FileUploader
              {...(formValues.assortmentFile && {
                files: [formValues.assortmentFile],
              })}
              handleUploadFiles={(files) =>
                setValue("assortmentFile", files[0], { shouldDirty: true })
              }
              mandatory
              accept={ACCEPT_FILES_TYPES.ASSORTMENT_FILE}
              limit={1}
              handleDeleteFile={() => {
                setValue("assortmentFile", undefined, {
                  shouldDirty: true,
                });
              }}
            />
          </Grid>
          <Grid item display="flex" flexDirection="column">
            <FormLabel>
              <FormattedMessage id="form.label.additionalPhotos" />
            </FormLabel>
            <FileUploader
              files={formValues.additionalPhotos}
              handleUploadFiles={(files) =>
                setValue("additionalPhotos", files, { shouldDirty: true })
              }
              accept={ACCEPT_FILES_TYPES.ADDITIONAL_PHOTOS}
              limit={5}
              handleDeleteFile={(index) => {
                handleDeleteAdditionalPhoto(index);
                setValue(
                  "additionalPhotos",
                  formValues.additionalPhotos?.splice(index, 1),
                );
              }}
            />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <OwnersTab
          product={product}
          register={register}
          canUpdateProduct={canUpdateProduct}
        />
      );
    }
  };

  return (
    <form>
      <Grid container>
        <Grid
          container
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <HighlightedFields
            season={season}
            product={product}
            register={register}
            canUpdateProduct={canUpdateProduct}
            displayBenefit={subCategory?.benefit}
          />
          <Grid
            item
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
          >
            <Carousel
              disabled={!canUpdateProduct}
              images={formValues.packshots}
              handleClickDelete={(deletedIndex) => {
                handleDeletePackshot(deletedIndex);
                const newArray = formValues.packshots?.filter(
                  (_, index) => index !== deletedIndex,
                );
                setValue("packshots", newArray);
              }}
            />
            <Grid
              item
              display="flex"
              flexDirection="column"
              gap={1}
              justifyContent="center"
            >
              <FileUploader
                handleUploadFiles={(files) => {
                  if (Array.isArray(formValues.packshots)) {
                    setValue("packshots", [...formValues.packshots, ...files], {
                      shouldDirty: true,
                    });
                  } else {
                    setValue("packshots", files, { shouldDirty: true });
                  }
                }}
                accept={ACCEPT_FILES_TYPES.PACKSHOTS}
                limit={10}
              />
              <Grid item>
                <GeographicScopeSelector<ProductPutForm>
                  fieldName="geographicScope"
                  value={formValues.geographicScope}
                  onChange={(scope) =>
                    setValue(
                      "geographicScope",
                      scope === formValues.geographicScope
                        ? GeographicScope.NULL
                        : scope,
                      {
                        shouldDirty: true,
                      },
                    )
                  }
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  sx={{ ml: 0 }}
                  control={
                    <Controller
                      name="newPackaging"
                      control={control}
                      render={({ field: props }) => (
                        <Checkbox
                          {...props}
                          checked={props.value}
                          onChange={(e) => {
                            props.onChange(e.target.checked);
                            setValue("newPackaging", e.target.checked, {
                              shouldDirty: true,
                            });
                          }}
                          disabled={!canUpdateProduct}
                        />
                      )}
                    />
                  }
                  labelPlacement="start"
                  label={
                    <FormLabel>
                      <FormattedMessage id="form.label.newPackaging" />
                    </FormLabel>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" />

          <Grid
            container
            item
            xs={3}
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-evenly"
          >
            <Grid item display="flex" justifyContent="space-between">
              <FormLabel>
                <FormattedMessage id="form.label.season" />
              </FormLabel>
              <Box>
                <Typography>{season?.name}</Typography>
              </Box>
            </Grid>
            <Grid item display="flex" justifyContent="space-between">
              <FormLabel>
                <FormattedMessage id="form.label.category" />
              </FormLabel>
              <Box>
                <Typography>
                  {category?.name === subCategory?.name
                    ? category?.name
                    : `${category?.name} - ${subCategory?.name}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item display="flex" justifyContent="space-between">
              <FormLabel>
                <FormattedMessage id="form.label.level" />
              </FormLabel>
              <Box>
                {canUpdateProduct ? (
                  <Select
                    fullWidth
                    variant="standard"
                    {...register("level", {
                      required: nestedIntl(
                        { id: "common.rules.required" },
                        { fieldName: "form.label.level" },
                      ),
                    })}
                    defaultValue={product.level}
                  >
                    {LevelOfActivationOption.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Typography>{product.level}</Typography>
                )}
              </Box>
            </Grid>
            <Grid item display="flex" justifyContent="space-between">
              <FormLabel>
                <FormattedMessage id="form.label.status" />
              </FormLabel>
              <Box>
                <Typography>{product.status}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item display="flex" alignSelf="flex-start">
            <ProductActions
              id={product.id}
              actions={[
                { action: MODAL_ACTION_TYPES.DUPLICATE_PRODUCT },
                {
                  action: product.isCancel
                    ? MODAL_ACTION_TYPES.UNDO_CANCEL
                    : MODAL_ACTION_TYPES.CANCEL,
                },
                { action: MODAL_ACTION_TYPES.DELETE_PRODUCT },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container item mx={1}>
          {canUpdateProduct ? (
            <TextField
              label={intl.formatMessage({
                id: "form.label.elementToHighlight",
              })}
              type="text"
              variant="standard"
              size="small"
              fullWidth
              {...register("elementToHighlight", {
                required: intl.formatMessage(
                  { id: "common.rules.required" },
                  { fieldName: "Element to highlight" },
                ),
              })}
            />
          ) : (
            <Grid item display="flex" flexDirection="column">
              <FormLabel>
                <FormattedMessage id="form.label.elementToHighlight" />
              </FormLabel>
              <Typography>{product.elementToHighlight}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid container sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleTabChange}>
            {[
              intl.formatMessage({ id: "product.header.tab.assortment" }),
              intl.formatMessage({ id: "product.header.tab.owners" }),
            ].map((tab) => (
              <Tab key={tab} label={tab} />
            ))}
          </Tabs>
        </Grid>
        <Box width="100%" sx={{ p: 2 }}>
          {_renderTab()}
        </Box>
      </Grid>
    </form>
  );
};

export default ProductPageHeaderData;
