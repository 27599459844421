import React, { type FC } from "react";
import type { UseFormRegister } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import { ArrowBackOutlined, InfoOutlined } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Box,
  Button,
  Chip,
  Fade,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import useNestedIntl from "@src/hooks/useNestedIntl";
import type { SeasonsGlobalInformations } from "@src/types";
import { useAppSelector } from "@store/hooks";
import { selectSeasons } from "@store/season/season.selector";
import { findSeasonById } from "@utils/fonctions.utils";

import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import type { ProductGetResponse, ProductPutForm } from "types/api/product";

interface Props {
  product: ProductGetResponse;
  canUpdateProduct?: boolean;
  register: UseFormRegister<ProductPutForm>;
  displayBenefit?: boolean;
  season?: SeasonsGlobalInformations;
}

const HighlightedFields: FC<Props> = ({
  product,
  canUpdateProduct,
  register,
  displayBenefit,
  season,
}) => {
  const intl = useIntl();
  const nestedIntl = useNestedIntl();
  const navigate = useNavigate();
  const seasons = useAppSelector(selectSeasons);

  const showDuplicate = product.duplicateOf || !!product.duplicatedIn?.length;

  const onClickNavigate = (seasonCleanName?: string) => {
    if (seasonCleanName) {
      const redirectWithParams = generatePath("/season/:seasonCleanName", {
        seasonCleanName,
      });
      navigate(redirectWithParams);
    }
  };

  return (
    <Grid
      item
      borderRadius="8px"
      sx={{ backgroundColor: "background.default" }}
      px={2}
      pt={1}
      pb={2}
      xs={4}
    >
      {season && (
        <Grid item mb={1}>
          <Button
            startIcon={<ArrowBackOutlined />}
            onClick={() => onClickNavigate(season.cleanName)}
          >
            {season.name}
          </Button>
        </Grid>
      )}
      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={showDuplicate ? 11 : 12}>
            {canUpdateProduct ? (
              <TextField
                type="text"
                variant="standard"
                fullWidth
                multiline
                inputProps={{
                  style: {
                    fontSize: 20,
                  },
                }}
                {...register("name", {
                  required: nestedIntl(
                    { id: "common.rules.required" },
                    { fieldName: "form.label.name" },
                  ),
                })}
              />
            ) : (
              <Typography variant="h5">{product.name}</Typography>
            )}
          </Grid>

          {showDuplicate && (
            <PopupState variant="popper">
              {(popupState) => (
                <Grid item xs={1}>
                  <IconButton {...bindToggle(popupState)} size="small">
                    <InfoOutlined />
                  </IconButton>
                  <Popper {...bindPopper(popupState)} transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                          <Grid
                            container
                            item
                            p={2}
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            minWidth={200}
                            justifyContent="space-between"
                          >
                            {product.duplicateOf && (
                              <Grid
                                item
                                display="flex"
                                gap={2}
                                justifyContent="space-between"
                              >
                                <FormLabel>
                                  <FormattedMessage id="product.duplicatedOf" />
                                </FormLabel>
                                <Chip
                                  size="small"
                                  label={
                                    findSeasonById(seasons, product.duplicateOf)
                                      ?.name
                                  }
                                  clickable
                                  onClick={() =>
                                    onClickNavigate(
                                      findSeasonById(
                                        seasons,
                                        product.duplicateOf,
                                      )?.cleanName,
                                    )
                                  }
                                />
                              </Grid>
                            )}
                            {!!product.duplicatedIn?.length && (
                              <Grid
                                item
                                display="flex"
                                gap={2}
                                justifyContent="space-between"
                              >
                                <FormLabel>
                                  <FormattedMessage id="product.duplicatedIn" />
                                </FormLabel>
                                <Grid item gap={2}>
                                  {product.duplicatedIn.map((duplicate) => (
                                    <Chip
                                      size="small"
                                      clickable
                                      label={
                                        findSeasonById(seasons, duplicate)?.name
                                      }
                                      key={duplicate}
                                      onClick={() =>
                                        onClickNavigate(
                                          findSeasonById(seasons, duplicate)
                                            ?.cleanName,
                                        )
                                      }
                                    />
                                  ))}
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </Grid>
              )}
            </PopupState>
          )}
        </Grid>
        {product.isCancel && (
          <Grid item>
            <Tooltip title={product.whyIsCancel}>
              <Chip
                color="error"
                variant="outlined"
                sx={{ ml: 1 }}
                icon={<CancelOutlinedIcon color="error" />}
                label={intl.formatMessage({ id: "product.status.cancelled" })}
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        display="flex"
        flexDirection="row"
        my={2}
        justifyContent="space-between"
      >
        <FormLabel>
          <FormattedMessage id="form.label.numberOfSkus" />
        </FormLabel>
        <Box display="flex" justifyContent="flex-end">
          {canUpdateProduct ? (
            <TextField
              type="number"
              sx={{ width: 40, textAlign: "right" }}
              inputProps={{
                sx: {
                  textAlign: "right",
                },
              }}
              variant="standard"
              {...register("numberOfSkus", {
                required: nestedIntl(
                  { id: "common.rules.required" },
                  { fieldName: "form.label.numberOfSkus" },
                ),
              })}
            />
          ) : (
            <Typography>{product.numberOfSkus}</Typography>
          )}
        </Box>
      </Grid>
      <Grid
        item
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <FormLabel>
          <FormattedMessage id="form.label.sellOutAmbition" />
        </FormLabel>
        <Box display="flex" justifyContent="flex-end">
          {canUpdateProduct ? (
            <TextField
              type="text"
              variant="standard"
              sx={{ width: 80 }}
              inputProps={{
                sx: {
                  textAlign: "right",
                },
              }}
              {...register("sellOutAmbition", {
                required: nestedIntl(
                  { id: "common.rules.required" },
                  { fieldName: "form.label.sellOutAmbition" },
                ),
              })}
            />
          ) : (
            <Typography>{product.sellOutAmbition}</Typography>
          )}
        </Box>
      </Grid>
      {displayBenefit && (
        <Grid
          item
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
        >
          <FormLabel>
            <FormattedMessage id="form.label.benefit-scent" />
          </FormLabel>
          <Box display="flex" justifyContent="flex-end">
            {canUpdateProduct ? (
              <TextField
                type="text"
                variant="standard"
                sx={{ width: 80 }}
                inputProps={{
                  sx: {
                    textAlign: "right",
                  },
                }}
                fullWidth
                {...register("benefit", {
                  required: nestedIntl(
                    { id: "common.rules.required" },
                    { fieldName: "form.label.benefit-scent" },
                  ),
                })}
              />
            ) : (
              <Typography>{product.benefit}</Typography>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default HighlightedFields;
