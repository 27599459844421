import { styled as MUIstyled } from "@mui/material";
import type { AssetContentFieldStatus } from "@src/types";

import styled from "styled-components";

interface IStyledImageContainer {
  isImgCarousel?: boolean;
  status?: AssetContentFieldStatus;
}

export type GeographicScopeSelectorType = {
  selected?: boolean;
  onClick?: () => void;
};

export const StyledImageContainer = styled.div<IStyledImageContainer>`
  max-width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledImageBubble = MUIstyled("div", {
  shouldForwardProp: (prop) => prop !== "selected" && prop !== "onClick",
})<GeographicScopeSelectorType>(({ selected = false, theme, onClick }) => ({
  width: "32px",
  height: "32px",
  borderRadius: "32px",
  border: `3px solid ${selected ? theme.palette.primary.main : theme.palette.primary.contrastText}`,
  ...(onClick && {
    "&:hover": {
      opacity: 0.5,
      cursor: "pointer",
    },
  }),
}));

export const StyledImagePlaceholder = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--light-grey-color);
`;

export const StyledImage = styled.img`
  height: 100%;
  object-fit: cover;
`;

export const StyledPreviewImage = MUIstyled("img", {
  shouldForwardProp: (prop) => prop !== "visible",
})<{ visible?: boolean }>(({ visible = false }) => ({
  position: "absolute",
  zIndex: 1,
  height: "200px",
  top: "100%",
  left: "0%",
  ...(!visible && {
    display: "none",
  }),
}));
