import React from "react";

import { SvgIcon } from "@mui/material";
import type { SvgIconProps } from "@mui/material";

const IconEqual: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
      >
        <path d="M8 18h16v4H8v-4zm0-8v4h16v-4H8z" />
      </svg>
    </SvgIcon>
  );
};

export default IconEqual;
