import React, { type FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import GenericFormField from "@components/Inputs/GenericFormField";
import GeographicScopeSelector from "@components/Inputs/GeographicScopeSelector";
import { Button, Chip, Grid, Stack } from "@mui/material";
import { ACCEPT_FILES_TYPES, FIELD_TYPES, type FieldConfig } from "@src/types";
import { useGetCategoryWithAxisQuery } from "@store/api/categories";
import { usePostProductMutation } from "@store/api/product";
import { useLazyGetSubCategoriesWithCategoryIdQuery } from "@store/api/subCategories";
import { useAppSelector } from "@store/hooks";
import { selectedSeason } from "@store/season/season.selector";
import { LevelOfActivationOption, StatusOption } from "@utils/constants.utils";
import { GeographicScope } from "@utils/data/enums/generals.enum";

import type {
  ProductForm,
  ProductPostBodySimpleField,
} from "types/api/product";

const AddProductModal: FC = () => {
  const navigate = useNavigate();
  const selectedSeasonState = useAppSelector(selectedSeason);
  const { data: categories = [] } = useGetCategoryWithAxisQuery({
    axisId: selectedSeasonState?.axisId,
  });
  const [getSubCategories, { data: subCategories }] =
    useLazyGetSubCategoriesWithCategoryIdQuery({
      selectFromResult: ({ data }) => ({
        data:
          data?.map((subCategory) => ({
            value: subCategory.id,
            label: subCategory.name,
            benefit: subCategory.benefit,
          })) || [],
      }),
    });
  const [createProduct, { data: product, isLoading }] =
    usePostProductMutation();

  const {
    watch,
    handleSubmit,
    register,
    setValue,
    control,
    trigger,
    formState: { isValid },
  } = useForm<ProductForm>({
    mode: "onBlur",
    defaultValues: {
      seasonCleanName: selectedSeasonState?.cleanName,
      geographicScope: GeographicScope.NULL,
      packshots: [],
      assortmentFile: [],
      additionalPhotos: [],
    },
  });

  const { name, category, subCategoryName, level, status } = watch();

  useEffect(() => {
    if (product) {
      const redirectWithParams = generatePath("/product/:id", {
        id: product.id.toString() || null,
      });
      navigate(redirectWithParams);
    }
  }, [product]);

  useEffect(() => {
    (async function fetch() {
      if (category && categories) {
        const { data } = await getSubCategories({
          category: parseInt(category),
        });
        if (data) {
          if (data.length === 1) {
            setValue("subCategoryName", data[0].name);
          }
        }
      }
    })();
  }, [category]);

  const formValues = watch();

  const onSubmit = async (formValues: ProductForm) => {
    await trigger();
    if (selectedSeasonState && isValid) {
      const productValues: ProductPostBodySimpleField = {
        name: formValues.name,
        status: formValues.status,
        level: formValues.level,
        mopOwner: formValues.mopOwner,
        productOwner: formValues.productOwner,
        geographicScope: formValues.geographicScope,
        elementToHighlight: formValues.elementToHighlight,
        benefit: formValues.benefit,
        sellOutAmbition: formValues.sellOutAmbition,
        assortmentFile: formValues.assortmentFile[0],
        additionalPhotos: formValues.additionalPhotos.filter(
          (additionalPhoto) => additionalPhoto instanceof File,
        ),
        packshots: formValues.packshots.filter(
          (packshot) => packshot instanceof File,
        ),
        seasonCleanName: formValues.seasonCleanName,
        subCategoryId: parseInt(formValues.subCategoryName),
        numberOfSkus: parseInt(formValues.numberOfSkus),
      };
      createProduct({ productValues, seasonName: selectedSeasonState.name });
    }
  };

  const [currentStep, setCurrentStep] = useState(0);
  const formConfig: Record<number, FieldConfig<ProductForm>[]> = {
    0: [
      {
        fieldName: "packshots",
        fieldType: FIELD_TYPES.CAROUSEL,
        images: formValues.packshots,
        limit: 10,
        accept: ACCEPT_FILES_TYPES.PACKSHOTS,
        mandatory: true,
      },
      {
        fieldName: "name",
        fieldType: FIELD_TYPES.INPUT_TEXT,
        mandatory: true,
      },
      {
        fieldName: "level",
        fieldType: FIELD_TYPES.SELECT,
        options: LevelOfActivationOption,
        mandatory: true,
      },
      {
        fieldName: "category",
        fieldType: FIELD_TYPES.SELECT,
        options: categories.map((cat) => ({
          value: cat.id,
          label: cat.name,
        })),
        mandatory: true,
      },
      {
        fieldName: "subCategoryName",
        fieldType: FIELD_TYPES.SELECT,
        options: subCategories,
        mandatory: true,
      },
      {
        fieldName: "status",
        fieldType: FIELD_TYPES.SELECT,
        options: StatusOption,
        mandatory: true,
      },
    ],
    1: [
      ...(subCategories.find(
        (subCategory) => subCategory.value === parseInt(subCategoryName),
      )?.benefit
        ? [
            {
              fieldName: "benefit",
              fieldType: FIELD_TYPES.INPUT_NUMBER,
            } as FieldConfig<ProductForm>,
          ]
        : []),
      {
        fieldName: "numberOfSkus",
        fieldType: FIELD_TYPES.INPUT_NUMBER,
        mandatory: true,
      },
      {
        fieldName: "sellOutAmbition",
        fieldType: FIELD_TYPES.INPUT_TEXT,
        mandatory: true,
      },
      {
        fieldName: "elementToHighlight",
        fieldType: FIELD_TYPES.INPUT_TEXT,
        mandatory: true,
      },
      {
        fieldName: "mopOwner",
        fieldType: FIELD_TYPES.INPUT_TEXT,
        mandatory: true,
      },
      {
        fieldName: "productOwner",
        fieldType: FIELD_TYPES.INPUT_TEXT,
        mandatory: true,
      },
      {
        fieldName: "assortmentFile",
        fieldType: FIELD_TYPES.FILE,
        limit: 1,
        accept: ACCEPT_FILES_TYPES.ASSORTMENT_FILE,
        mandatory: true,
        files: formValues.assortmentFile,
      },
      {
        fieldName: "additionalPhotos",
        fieldType: FIELD_TYPES.CAROUSEL,
        images: formValues.additionalPhotos,
        limit: 5,
        accept: ACCEPT_FILES_TYPES.ADDITIONAL_PHOTOS,
      },
    ],
  };

  const handleClickPrev = async () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const handleClickNext = async () => {
    await trigger(formConfig[currentStep].map((field) => field.fieldName));
    if (isValid) {
      setCurrentStep((prevState) => prevState + 1);
    }
  };

  const selectedCategory = categories.find(
      (cat) => cat.id?.toString() === category?.toString(),
    )?.name,
    selectedSubCategory = subCategories.find(
      (subCat) => subCat.value?.toString() === subCategoryName?.toString(),
    )?.label;

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {currentStep === 1 && (
        <Grid container>
          <Stack direction="row" spacing={1}>
            {[
              selectedSeasonState?.name,
              name,
              `${selectedCategory} - ${selectedSubCategory}`,
              level,
              status,
            ].map((value) => (
              <Chip label={value} key={value} size="small" />
            ))}
          </Stack>
        </Grid>
      )}
      <Grid container display="flex" justifyContent="space-between">
        <Grid item display="flex" flexDirection="column" flex={1}>
          {formConfig[currentStep]?.map((field) => (
            <Grid item key={field.fieldName} my={1} sx={{ width: "100%" }}>
              <GenericFormField
                field={field}
                setValue={setValue}
                register={register}
                control={control}
                watch={watch}
              />
            </Grid>
          ))}
          {currentStep === 0 && (
            <Grid item>
              <GeographicScopeSelector<ProductForm>
                fieldName="geographicScope"
                value={formValues.geographicScope}
                onChange={(scope) =>
                  setValue(
                    "geographicScope",
                    scope === formValues.geographicScope
                      ? GeographicScope.NULL
                      : scope,
                    {
                      shouldDirty: true,
                    },
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container my={2}>
        {currentStep === 0 ? (
          <Grid item display="flex" justifyContent="flex-end" width="100%">
            <Button onClick={handleClickNext} variant="contained">
              <FormattedMessage id="common.next" />
            </Button>
          </Grid>
        ) : (
          <Grid item display="flex" justifyContent="space-between" width="100%">
            <Button onClick={handleClickPrev} variant="outlined">
              <FormattedMessage id="common.back" />
            </Button>

            <Button disabled={isLoading} type="submit" variant="contained">
              <FormattedMessage id="product.button.add" />
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default AddProductModal;
