import React, { type FC } from "react";

import { Grid, Skeleton, Stack } from "@mui/material";

const SeasonPageHeader: FC = () => (
  <Grid
    container
    m={2}
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    width="auto"
  >
    <Grid item display="flex">
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={240} height={40} />
        <Skeleton sx={{ fontSize: "2rem" }} />
        <Skeleton sx={{ fontSize: "2rem" }} />
        <Skeleton variant="rounded" height={30} sx={{ my: 2 }} />
      </Stack>
    </Grid>
    <Grid item display="flex">
      <Skeleton variant="rectangular" width={600} height={140} />
    </Grid>
  </Grid>
);

export default SeasonPageHeader;
