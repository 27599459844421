import { createSlice } from "@reduxjs/toolkit";

import { type SharedProps } from "notistack";
import { getRandomKey } from "utils/common.utils";

export type SnackbarProps = {
  severity: SharedProps["variant"];
  message: string;
  translation?: boolean;
  key?: number;
};

export interface CommonState {
  displayMenu: boolean;
  snackbars: SnackbarProps[];
}

const initialState: CommonState = {
  displayMenu: false,
  snackbars: [],
};

export const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    addSnackbar: (state, { payload }: { payload: SnackbarProps }) => {
      state.snackbars.push({ ...payload, key: getRandomKey() });
    },
    removeSnackbar: (state, { payload }: { payload: number }) => {
      state.snackbars = state.snackbars.filter(({ key }) => key != payload);
    },
  },
});

export const { addSnackbar, removeSnackbar } =
  commonReducer.actions;

export default commonReducer.reducer;
