import React, { type FC } from "react";

import { Box } from "@mui/material";
import { keyframes } from "@mui/system";

const dotsAnimation = keyframes`
  0% { content: "."; }
  33% { content: ".."; }
  66% { content: "..."; }
  100% { content: ""; }
`;

interface Props {
  text: string;
}

export const LoadingText: FC<Props> = ({ text }) => (
  <Box
    sx={{
      display: "inline-block",
      "&::after": {
        content: '""',
        animation: `${dotsAnimation} 3s steps(3, end) infinite`,
      },
    }}
  >
    {text}
  </Box>
);
