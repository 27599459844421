import type { FieldPath, FieldValues } from "react-hook-form";

import type { CarouselProps } from "@components/Carousel/Carousel";
import type { DropzoneProps } from "@components/Dropzone/Dropzone";
import type { FileUploaderProps } from "@components/FileUploader/FileUploader";
import type { IFormListInput } from "@components/Inputs/FormListInput";
import type { CheckboxProps, SelectProps, TextFieldProps } from "@mui/material";
import type { DatePickerProps } from "@mui/x-date-pickers";

export type Credentials = {
  accessToken: string;
  idToken: string;
  apiKey: string;
};

export type SelectOptionType = {
  label: string;
  value: string | number;
  benefit?: boolean;
};

export enum METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export enum MODAL_ACTION_TYPES {
  NONE = "NONE",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  DELETE_ASSET = "DELETE_ASSET",
  ADD_PRODUCT = "ADD_PRODUCT",
  DUPLICATE_PRODUCT = "DUPLICATE_PRODUCT",
  CANCEL = "CANCEL",
  UNDO_CANCEL = "UNDO_CANCEL",
  SET_WAITING_FOR_APPROVAL = "SET_WAITING_FOR_APPROVAL",
  UNSET_WAITING_FOR_APPROVAL = "UNSET_WAITING_FOR_APPROVAL",
  CLOSE_BRIEF = "CLOSE_BRIEF",
  REOPEN_BRIEF = "REOPEN_BRIEF",
  DELIVER_ASSET = "DELIVER_ASSET",
  UNDELIVER_ASSET = "UNDELIVER_ASSET",
}

export enum FIELD_TYPES {
  BOOLEAN,
  SELECT,
  INPUT_TEXT,
  INPUT_NUMBER,
  DATE,
  CAROUSEL,
  FILE,
  MULTI_SELECT,
}

export type FieldConfig<T extends FieldValues> = {
  fieldName: FieldPath<T>;
  mandatory?: boolean;
} & (
  | ({
      fieldType: FIELD_TYPES.SELECT;
      options: SelectOptionType[];
    } & SelectProps)
  | ({ fieldType: FIELD_TYPES.DATE } & DatePickerProps<any>)
  | ({ fieldType: FIELD_TYPES.INPUT_TEXT } & TextFieldProps)
  | ({ fieldType: FIELD_TYPES.INPUT_NUMBER } & TextFieldProps)
  | ({ fieldType: FIELD_TYPES.CAROUSEL } & Partial<CarouselProps> &
      Partial<DropzoneProps>)
  | ({ fieldType: FIELD_TYPES.FILE } & Partial<FileUploaderProps>)
  | ({
      fieldType: FIELD_TYPES.MULTI_SELECT;
      options: string[];
    } & Partial<IFormListInput<FieldValues>>)
  | ({ fieldType: FIELD_TYPES.BOOLEAN } & CheckboxProps)
);

export type ApiError = {
  context: string;
  errorCode: string;
  errorName: string;
  messages?: string[];
  method: string;
  path: string;
  stack?: string;
  statusCode: number;
};
