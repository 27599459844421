export enum AssetTypology {
  COMPOS = "COMPOS",
  SMEAR = "SMEAR",
  VIDEO = "VIDEO",
  INCARNE_DIGIT = "INCARNE_DIGIT",
  KV_CAMPAIGN_PRINT = "KV_CAMPAIGN_PRINT",
  FULL = "FULL",
}

export type ExportRequest = {
  seasonCleanName: string;
  typology: AssetTypology;
};
