export enum API_TAGS {
  PRODUCT = "PRODUCT",
  PRODUCTS = "PRODUCTS",
  SEASONS = "SEASONS",
  SEASON = "SEASON",
}

export enum SEASON_TYPES {
  SPRING = "SPRING",
  SUMMER = "SUMMER",
  FALL = "FALL",
  HOLIDAY = "HOLIDAY",
  MEDIA_ALWAYS_ON = "MEDIA ALWAYS-ON",
}

export enum EditableTypes {
  NO = "NO",
  AT_CREATION = "AT_CREATION",
  YES = "YES",
}

export enum FieldTypes {
  TEXT = "TEXT",
  FILE = "FILE",
  FILE_LIST = "FILE_LIST",
  LIST = "LIST",
  BOOLEAN = "BOOLEAN",
}

export enum AssetTag {
  ASSET_ALREADY_REQUESTED_ON_ANOTHER_PRODUCT = "Asset already requested on another product",
  EXTEND_RIGHTS = "Extend rights",
  NEW_ASSET_ADDED = "New asset added",
  TOP_PRIORITY_ASSET = "Top priority asset",
}

export enum ACCEPT_FILES_TYPES {
  ADDITIONAL_PHOTOS = ".png,.jpeg,.jpeg,.pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ASSORTMENT_FILE = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
  MERCH_ANNEXES = ".pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation",
  PACKSHOTS = ".png,.jpeg,.jpg",
  BENCH_VISUALS = ".png,.jpeg,.jpg",
}

export enum ALERT_TYPES {
  TOO_MUCH_FILES = "this field can contain only $numberOfFile images",
}

export enum TEXT_REPLACE_TYPES {
  NUMBER_OF_FILE = "$numberOfFile",
}

export enum AssetContentFieldStatus {
  NULL = "NULL", //Used to differentiate missing vs change before date
  DELETED = "DELETED",
  MODIFIED = "MODIFIED",
  CREATED = "CREATED",
}
