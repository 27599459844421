import { BaseDto } from "@src/dto/base.dto";
import type { SeasonPutRequest } from "@src/types";

export class SeasonPutRequestDto extends BaseDto {
  year: string;
  deadlineDef: Date;
  deadlineNoDef: Date;
  merchAnnexes?: File[];

  constructor(season: SeasonPutRequest) {
    super();
    this.year = season.year;
    this.deadlineDef = season.deadlineDef;
    this.deadlineNoDef = season.deadlineNoDef;
    this.merchAnnexes = season.merchAnnexes;
  }
}
