import React, { type FC } from "react";

import { Paper } from "@mui/material";
import SeasonPageHeaderData from "@pages/SeasonPage/components/SeasonPageHeader/SeasonPageHeaderData";
import SeasonPageHeaderSkeleton from "@pages/SeasonPage/components/SeasonPageHeader/SeasonPageHeaderSkeleton";
import type { SeasonResponse } from "@src/types";

interface Props {
  season?: SeasonResponse;
  isLoading?: boolean;
}

const SeasonPageHeader: FC<Props> = ({ season, isLoading }) => (
  <Paper sx={{ p: 2, position: "sticky", top: 0, zIndex: 3 }}>
    {isLoading ? (
      <SeasonPageHeaderSkeleton />
    ) : season ? (
      <SeasonPageHeaderData season={season} />
    ) : null}
  </Paper>
);
export default SeasonPageHeader;
