import React, { type FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useNestedIntl from "@src/hooks/useNestedIntl";
import type { DeliverRequest } from "@src/types";
import { useDeliverAssetMutation } from "@store/api/asset";

interface Props {
  onClose: () => void;
  id: number;
  isDelivered?: boolean;
  defaultValues?: DeliverRequest;
}

const DeliverModal: FC<Props> = ({
  onClose,
  id,
  isDelivered = false,
  defaultValues,
}) => {
  const intl = useIntl();
  const nestedIntl = useNestedIntl();
  const [deliverAsset, { isLoading, isSuccess }] = useDeliverAssetMutation();
  const {
    register,
    watch,
    trigger,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<DeliverRequest>({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      whyNotDelivered: defaultValues?.whyNotDelivered
        ? defaultValues.whyNotDelivered
        : "",
    },
  });

  const whyNotDelivered = watch("whyNotDelivered");

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onSubmit = async () => {
    if (!isDelivered) {
      await trigger();
    }
    if (isValid) {
      deliverAsset({
        id,
        whyNotDelivered,
        isDelivered,
      });
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {!isDelivered && (
        <Box my={4}>
          <TextField
            label={intl.formatMessage({ id: "form.label.whyNotDelivered" })}
            type="text"
            fullWidth
            error={!!errors.whyNotDelivered}
            {...register("whyNotDelivered", {
              required: nestedIntl(
                { id: "common.rules.required" },
                { fieldName: "common.reason.label" },
              ),
            })}
          />
          {errors.whyNotDelivered && (
            <FormHelperText error>
              {errors.whyNotDelivered?.message}
            </FormHelperText>
          )}
        </Box>
      )}

      <Typography>
        {isDelivered ? (
          <FormattedMessage id="common.deliver.modal.desc.delivered.label" />
        ) : (
          <FormattedMessage id="common.deliver.modal.desc.not-delivered.label" />
        )}
      </Typography>

      <Grid container my={2}>
        <Grid item display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage id="common.cancel" />
          </Button>

          <Button disabled={isLoading} type="submit" variant="contained">
            <FormattedMessage id="common.confirm" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DeliverModal;
