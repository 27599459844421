import { createSelector } from "@reduxjs/toolkit";
import { type Authority } from "@src/types";
import type { RootState } from "@store/store";
import { hasAuthority } from "@utils/auth.utils";

export const selectCredentials = ({ auth }: RootState) => auth.credentials;

export const selectAuth = ({ auth }: RootState) => auth;

export const selectRoles = ({ auth }: RootState) => auth.roles || [];

export const selectUserHasAuthority = createSelector(
  [selectAuth, (_state: RootState, authority: Authority) => authority],
  (auth, authority) =>
    auth.roles ? hasAuthority(auth.roles, authority) : false,
);
