import React, { type FC } from "react";

import type { GeographicScopeSelectorType } from "@components/Image/Image.styled";
import { StyledImage, StyledImageBubble } from "@components/Image/Image.styled";
import flagChina from "@resources/images/flag/flag_china.png";
import flagUsaAndCanada from "@resources/images/flag/flag_usa_canada.png";
import { GeographicScope } from "@utils/data/enums/generals.enum";

interface Props extends GeographicScopeSelectorType {
  scope: GeographicScope;
}

const GeographicScopeComponent: FC<Props> = ({ scope, ...props }) => {
  const getGeographicScopeSrc = () => {
    switch (scope) {
      case GeographicScope.CHINA:
        return flagChina;
      case GeographicScope.NORTHAMERICA:
        return flagUsaAndCanada;
      case GeographicScope.NULL:
        return "";
    }
  };

  if (scope === GeographicScope.NULL) {
    return null;
  }

  return (
    <StyledImageBubble {...props}>
      <StyledImage
        src={getGeographicScopeSrc()}
        {...(props.onClick && { onClick: props.onClick })}
      />
    </StyledImageBubble>
  );
};

export default GeographicScopeComponent;
