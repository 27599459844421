import type { FC } from "react";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AddAssetModal from "@components/Modals/AddAssetModal";
import ModalLayout from "@components/Modals/ModalLayout";
import { AddOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import AssetsElementList from "@pages/ProductPage/components/AssetsElementList/AssetsElementList";
import type { ProductGetResponse } from "@src/types";
import { Authority } from "@src/types";
import { useAppSelector } from "@store/hooks";
import { selectCanModifySeasonData } from "@store/season/season.selector";
import { colors } from "@theme/theme";

interface Props {
  product: ProductGetResponse;
}

const AssetsList: FC<Props> = ({ product }) => {
  const intl = useIntl();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const canCreateAsset = useAppSelector((state) =>
    selectCanModifySeasonData(state, Authority.CREATE_ASSET),
  );

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <Box m={2}>
      <Grid
        container
        item
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
      >
        <Grid item display="flex" alignItems="center" gap={2}>
          <Grid item>
            <Typography variant="h4">
              <FormattedMessage
                id="asset.results.nb"
                values={{ count: product.nbAssets }}
              />
            </Typography>
          </Grid>
          {product.nbModifiedAssets > 0 && (
            <Grid item>
              <Chip
                color="warning"
                label={intl.formatMessage(
                  { id: "asset.modified-assets.nb" },
                  { count: product.nbModifiedAssets },
                )}
              />
            </Grid>
          )}
          {product.nbPriorityAssets > 0 && (
            <Grid item>
              <Chip
                sx={{
                  width: "100%",
                  backgroundColor: colors.priority,
                  color: colors.white,
                }}
                label={intl.formatMessage(
                  { id: "asset.top-priority-assets.nb" },
                  { count: product.nbPriorityAssets },
                )}
              />
            </Grid>
          )}
        </Grid>

        {canCreateAsset && (
          <Grid item>
            <Button
              variant="contained"
              onClick={handleOpenPopup}
              startIcon={<AddOutlined />}
              fullWidth
            >
              <FormattedMessage id="asset.button.add" />
            </Button>
            <ModalLayout
              open={isPopupOpen}
              onClose={handleClosePopup}
              title={intl.formatMessage({ id: "asset.button.add" })}
            >
              <AddAssetModal
                productId={product.id}
                onClose={handleClosePopup}
              />
            </ModalLayout>
          </Grid>
        )}
      </Grid>

      {product.assets.map((asset) => (
        <AssetsElementList asset={asset} key={asset.id} />
      ))}
    </Box>
  );
};

export default AssetsList;
