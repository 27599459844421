import { type MessageDescriptor, useIntl } from "react-intl";

import type { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";

const useNestedIntl = () => {
  const intl = useIntl();

  const translateMessage = (
    descriptor: MessageDescriptor,
    values: Record<
      string,
      PrimitiveType | FormatXMLElementFn<string, string>
    > = {},
  ) => {
    const newValues = { ...values };
    let hasNestedKeys = false;

    for (const key in values) {
      if (key in values) {
        const value = values[key];
        if (typeof value === "string") {
          newValues[key] = translateMessage({ id: value });
          hasNestedKeys = true;
        }
      }
    }

    if (!hasNestedKeys) {
      return intl.formatMessage(descriptor, values);
    }

    return intl.formatMessage(descriptor, newValues);
  };

  return translateMessage;
};

export default useNestedIntl;
