import { createSelector } from "@reduxjs/toolkit";
import type { Authority } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";

import { type RootState } from "store/store";

export const selectSeasons = ({ season }: RootState) => season.seasons;
export const selectedSeason = ({ season }: RootState) => season.selectedSeason;
export const selectAreWeBeforeNoDefDate = createSelector(
  selectedSeason,
  (selectedSeason) => {
    if (!selectedSeason) {
      return false;
    }
    const currentDate = new Date();
    return currentDate < new Date(selectedSeason.deadlineNoDef);
  },
);

export const selectAreWeAfterDefDate = createSelector(
  selectedSeason,
  (selectedSeason) => {
    if (!selectedSeason) {
      return false;
    }
    const currentDate = new Date();
    return currentDate > new Date(selectedSeason.deadlineDef);
  },
);

export const selectIsSeasonOpen = createSelector(
  selectedSeason,
  (selectedSeason) => {
    if (!selectedSeason) {
      return false;
    }
    return !selectedSeason.isClosed;
  },
);

export const selectCanModifySeasonData = createSelector(
  [
    selectIsSeasonOpen,
    (state: RootState, authority: Authority) =>
      selectUserHasAuthority(state, authority),
  ],
  (isSeasonOpen, userHasAuthority) => {
    return isSeasonOpen && userHasAuthority;
  },
);
