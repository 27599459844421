export const getOrThrow = (name: string, defaultValue = ""): string => {
  if (process.env[name] === undefined && !defaultValue) {
    throw Error(`Environment variable '${name}' not found !`);
  }
  return process.env[name] ?? defaultValue;
};

export const ENV = getOrThrow("REACT_APP_API_ENV");
export const API_URL = getOrThrow("REACT_APP_API_URL");
export const OKTA_ISSUER = getOrThrow("REACT_APP_OKTA_ISSUER");
export const OKTA_REDIRECT_URI = getOrThrow("REACT_APP_OKTA_REDIRECT_URI");
export const OKTA_LOGOUT_URL = getOrThrow("REACT_APP_OKTA_LOGOUT_URL");
export const OKTA_CLIENT_ID = getOrThrow("REACT_APP_OKTA_CLIENT_ID");
