import asset from "./asset.json";
import common from "./common.json";
import form from "./form.json";
import product from "./product.json";
import season from "./season.json";
import snackbar from "./snackbar.json";

const en = {
  ...asset,
  ...common,
  ...form,
  ...season,
  ...product,
  ...snackbar,
};

export default en;
