import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useSnackbar as useNotistack } from "notistack";
import { removeSnackbar } from "store/common/common.reducer";
import { selectSnackbars } from "store/common/common.selector";
import { useAppDispatch, useAppSelector } from "store/hooks";

const useSnackbar = () => {
  const [displayed, setDisplayed] = useState<number[]>([]);
  const snackbars = useAppSelector(selectSnackbars);
  const { enqueueSnackbar } = useNotistack();
  const intl = useIntl();
  const dispatch = useAppDispatch();

  useEffect(() => {
    snackbars.forEach(({ message, severity, key, translation }) => {
      if (key && displayed.includes(key)) {
        return;
      }

      const snackMessage = Array.isArray(message) ? message[0] : message;
      const translatedMsg = translation
        ? intl.formatMessage({ id: snackMessage })
        : snackMessage;

      enqueueSnackbar(translatedMsg, {
        variant: severity,
        autoHideDuration: 5000,
        onExited: () => {
          if (key) {
            dispatch(removeSnackbar(key));
            setDisplayed((prev) => prev.filter((k) => k !== key));
          }
        },
      });

      if (key) {
        setDisplayed((prev) => [...prev, key]);
      }
    });
  }, [snackbars]);
};

export default useSnackbar;
