import React, { type FC, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FileUploaderInput } from "@components/FileUploader/FileUploader.styled";
import { StyledPreviewImage } from "@components/Image/Image.styled";
import { AutorenewOutlined } from "@mui/icons-material";
import { Button, Chip, Stack, Typography } from "@mui/material";
import type { ACCEPT_FILES_TYPES, FileComponentGetDetails } from "@src/types";
import { isImage } from "@utils/fonctions.utils";

export interface FileUploaderProps {
  files?: (File | FileComponentGetDetails)[];
  limit: number;
  accept: ACCEPT_FILES_TYPES;
  disabled?: boolean;
  mandatory?: boolean;
  handleUploadFiles: (files: File[]) => void;
  handleDeleteFile?: (index: number) => void;
}

const FileUploader: FC<FileUploaderProps> = ({
  files = [],
  disabled,
  mandatory,
  handleUploadFiles,
  handleDeleteFile,
  limit,
  accept,
}) => {
  const [displayedPreview, setDisplayedPreview] = useState<
    number | undefined
  >();
  const [isReplacing, setIsReplacing] = useState<number | undefined>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleDelete = (index: number) => {
    if (handleDeleteFile) {
      handleDeleteFile(index);
    }
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { files: filesList },
    } = event;
    if (isReplacing !== undefined) {
      handleDelete(isReplacing);
      setIsReplacing(undefined);
    }
    if (filesList) {
      const newFiles = Array.from(filesList);
      handleUploadFiles(newFiles);
    }
  };

  const shouldReplaceFile = limit === 1 && files.length === 1 && mandatory;

  return (
    <>
      <FileUploaderInput
        ref={(e) => {
          fileInputRef.current = e;
        }}
        type="file"
        onChange={handleFileInputChange}
        multiple={limit > 1}
        accept={accept}
        disabled={disabled}
      />
      <Stack direction="row" gap={1}>
        {files.map((file, index) => (
          <Chip
            key={index}
            sx={{ mt: 0.75 }}
            size="small"
            onMouseOver={() => setDisplayedPreview(index)}
            onMouseLeave={() => setDisplayedPreview(undefined)}
            label={
              <Typography>
                {file instanceof File ? (
                  file.name
                ) : (
                  <>
                    {file.fileName}
                    {isImage(file) && (
                      <StyledPreviewImage
                        visible={displayedPreview === index}
                        src={
                          file instanceof File
                            ? URL.createObjectURL(file)
                            : file.url
                        }
                      />
                    )}
                  </>
                )}
              </Typography>
            }
            {...(!disabled &&
              !shouldReplaceFile && { onDelete: () => handleDelete(index) })}
            {...(!disabled &&
              shouldReplaceFile && {
                clickable: true,
                icon: <AutorenewOutlined />,
                onClick: () => {
                  setIsReplacing(index);
                  fileInputRef.current?.click();
                },
              })}
          />
        ))}
        {!shouldReplaceFile && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClick}
            disabled={disabled || files.length >= limit}
          >
            <FormattedMessage id="common.upload-file" />
          </Button>
        )}
      </Stack>
    </>
  );
};

export default FileUploader;
