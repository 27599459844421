import type { AxisDetails, AxisDetailsRequest } from "@src/types";
import endpoints from "@store/api/endpoints";

import { defaultApi } from "store/api/api";

export const axisApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getAxis: build.query<AxisDetails[], void>({
      query: () => ({
        url: endpoints.axis.all,
      }),
    }),
    getAxisWithSeason: build.query<AxisDetails[], AxisDetailsRequest>({
      query: (params) => ({
        url: endpoints.axis.all,
        params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetAxisQuery,
  useLazyGetAxisWithSeasonQuery,
  useGetAxisWithSeasonQuery,
} = axisApi;
