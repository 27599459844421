import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_TAGS } from "@src/types";
import { X_ACCESS_TOKEN, X_API_KEY, X_ID_TOKEN } from "@utils/constants.utils";

import queryString from "query-string";
import { type RootState } from "store/store";
import { API_URL } from "utils/env.utils";

export const defaultApi = createApi({
  reducerPath: "api",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
      const credentials = (getState() as RootState).auth.credentials;

      if (credentials) {
        const { accessToken, idToken, apiKey } = credentials;
        headers.set(X_ACCESS_TOKEN, accessToken);
        headers.set(X_ID_TOKEN, idToken);
        headers.set(X_API_KEY, apiKey);
      }

      return headers;
    },
    paramsSerializer: (params) =>
      queryString.stringify(params, { arrayFormat: "bracket" }),
  }),
  tagTypes: Object.values(API_TAGS),
  endpoints: () => ({}),
});
