import React, { type FC } from "react";

interface Props {
  color?: string;
}

const LogoSephoraCollection: FC<Props> = ({ color }) => {
  return (
    <svg
      version="1.1"
      id="Calque_2"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      {...(color && { fill: color })}
      viewBox="0 0 227 70"
    >
      <g>
        <g>
          <path
            d="M112.92,46.12c1.02,0,1.97,0.3,2.52,0.66l0.2-0.89c-0.65-0.38-1.59-0.65-2.82-0.65c-2.53,0-5.07,1.85-5.07,4.95
    c0,3.08,2.51,4.95,5.07,4.95c1.2,0,1.97-0.18,2.56-0.51l0.24-1.02c-0.68,0.42-1.59,0.65-2.7,0.65c-2.19,0-4.16-1.55-4.16-4.07
    C108.77,47.66,110.76,46.12,112.92,46.12"
          />
          <path
            d="M124.89,45.25c-2.64,0-5.01,1.98-5.01,4.94c0,2.99,2.37,4.97,5.01,4.97c2.64,0,5.01-1.98,5.01-4.97
    C129.9,47.23,127.53,45.25,124.89,45.25 M124.89,54.27c-2.1,0-3.99-1.62-3.99-4.08c0-2.46,1.89-4.07,3.99-4.07
    c2.09,0,3.99,1.61,3.99,4.07C128.88,52.64,126.98,54.27,124.89,54.27"
          />
          <g>
            <polygon points="135.15,45.41 134.14,45.41 134.14,54.99 139.61,54.99 139.61,54.12 135.15,54.12 			" />
          </g>
          <g>
            <polygon points="144.85,45.41 143.85,45.41 143.85,54.99 149.31,54.99 149.31,54.12 144.85,54.12 			" />
          </g>
          <g>
            <polygon
              points="154.56,50.61 157.83,50.61 157.83,49.74 154.56,49.74 154.56,46.28 158.55,46.28 158.55,45.41 153.55,45.41
				153.55,54.99 159.07,54.99 159.07,54.12 154.56,54.12 			"
            />
          </g>
          <path
            d="M168.49,46.12c1.02,0,1.97,0.3,2.52,0.66l0.2-0.89c-0.64-0.38-1.59-0.65-2.82-0.65c-2.54,0-5.07,1.85-5.07,4.95
			c0,3.08,2.51,4.95,5.07,4.95c1.2,0,1.97-0.18,2.57-0.51l0.24-1.02c-0.67,0.42-1.59,0.65-2.7,0.65c-2.19,0-4.16-1.55-4.16-4.07
			C164.33,47.66,166.33,46.12,168.49,46.12"
          />
          <g>
            <polygon
              points="175.45,46.31 178.72,46.31 178.72,54.99 179.72,54.99 179.72,46.31 183.02,46.31 183.02,45.44 175.45,45.44
				"
            />
          </g>
          <g>
            <rect x="187.26" y="45.44" width="1" height="9.54" />
          </g>
          <path
            d="M197.5,45.28c-2.63,0-5,1.98-5,4.92c0,2.98,2.36,4.95,5,4.95c2.63,0,5-1.98,5-4.95C202.49,47.25,200.13,45.28,197.5,45.28
			 M197.5,54.27c-2.09,0-3.98-1.62-3.98-4.07c0-2.45,1.89-4.05,3.98-4.05c2.08,0,3.98,1.6,3.98,4.05
			C201.48,52.65,199.58,54.27,197.5,54.27"
          />
          <g>
            <path
              d="M214.04,45.44v5.56c0,0.64,0,1.38,0.03,2.08l-0.03,0.02c-0.58-0.73-1.32-1.57-1.94-2.29l-4.65-5.37h-0.7v9.54h1v-5.76
				c0-0.69,0-1.32-0.03-2.09l0.03-0.01c0.57,0.7,1.32,1.57,1.98,2.3l5.06,5.78h0.27v-9.75H214.04z"
            />
          </g>
          <g>
            <path
              d="M95.98,45.43l-3.24,9.5h1.54l0.7-2.24h3.62l0.7,2.24l1.54,0l-3.24-9.5H95.98z M95.36,51.49l1.41-4.55h0.02l1.42,4.55
				L95.36,51.49z"
            />
          </g>
          <path
            d="M26.45,47.77c0-1.54,2.04-1.61,3.26-0.61c0.43-1.19,0.46-1.28,0.46-1.28c-3.52-1.7-5.14,0.54-5.14,1.99
			c0,3.43,4.85,2.68,4.16,5.1c-0.25,0.88-1.8,1.39-3.6,0.29c-0.15,0.36-0.36,0.8-0.57,1.25c2.38,1.25,5.46,0.65,5.57-1.94
			C30.71,49.35,26.45,49.57,26.45,47.77"
          />
          <g>
            <polygon
              points="35.09,54.92 41.03,54.92 41.03,53.69 36.57,53.69 36.57,50.47 39.73,50.47 39.73,49.28 36.57,49.28 36.57,46.7
				41.03,46.7 41.03,45.44 35.09,45.44 			"
            />
          </g>
          <g>
            <polygon
              points="62.97,49.28 58.13,49.28 58.13,45.44 56.68,45.44 56.68,54.93 58.13,54.93 58.13,50.47 62.97,50.47 62.97,54.93
				64.42,54.93 64.42,45.44 62.97,45.44 			"
            />
          </g>
          <path
            d="M73.58,45.22c-2.75,0-4.97,2.23-4.97,4.97c0,2.75,2.23,4.97,4.97,4.97c2.75,0,4.97-2.23,4.97-4.97
			C78.56,47.45,76.33,45.22,73.58,45.22 M73.58,53.86c-1.94,0-3.51-1.64-3.51-3.67s1.57-3.67,3.51-3.67c1.94,0,3.51,1.64,3.51,3.67
			S75.52,53.86,73.58,53.86"
          />
          <path
            d="M48.74,45.42h-2.57l0,9.5h1.48c0,0,0-1.82,0-3.6c0,0,1.22,0.01,1.63-0.03c1.33-0.11,3.27-0.46,3.27-3.06
			C52.55,45.3,49.28,45.42,48.74,45.42 M48.68,50.13c-0.45,0-0.66,0-1.03,0v-3.51l0.88,0c0.38,0,2.63-0.12,2.7,1.62
			C51.29,49.99,49.51,50.13,48.68,50.13"
          />
          <path
            d="M89.17,48.25c0-2.93-3.27-2.81-3.81-2.81h-2.57l0,9.5h1.48c0,0,0-1.82,0-3.6c0,0,1.22,0,1.63-0.03l0.02,0l2.45,3.64h1.79
			l-2.76-3.93C88.35,50.69,89.17,49.96,89.17,48.25 M85.31,50.15c-0.45,0-0.66,0-1.03,0c0-1.77,0-3.51,0-3.51l0.88,0
			c0.39,0,2.63-0.12,2.7,1.62C87.92,50.01,86.14,50.15,85.31,50.15"
          />
        </g>
        <g>
          <rect x="11.78" y="43.32" width="9.51" height="2.19" />
        </g>
        <g>
          <rect x="11.78" y="47.7" width="9.51" height="2.19" />
        </g>
        <g>
          <rect x="11.78" y="52.08" width="9.51" height="2.19" />
        </g>
        <g>
          <rect x="11.78" y="56.46" width="9.51" height="2.19" />
        </g>
      </g>
      <g>
        <path
          d="M11.4,13.43h9.4c2.23,0,3.93,0.54,5.09,1.61c1.16,1.07,1.74,2.62,1.74,4.64c0,1.22-0.22,2.24-0.66,3.04
		c-0.44,0.8-1.11,1.49-2.01,2.04c0.9,0.17,1.67,0.43,2.3,0.79c0.63,0.35,1.15,0.79,1.55,1.3c0.4,0.52,0.68,1.09,0.85,1.74
		c0.17,0.64,0.26,1.33,0.26,2.06c0,1.14-0.2,2.15-0.6,3.03c-0.4,0.88-0.96,1.62-1.67,2.22c-0.72,0.6-1.59,1.05-2.62,1.35
		c-1.03,0.3-2.19,0.45-3.48,0.45H11.4V13.43z M17.71,22.96h1.16c1.97,0,2.96-0.78,2.96-2.35c0-1.57-0.99-2.35-2.96-2.35h-1.16V22.96
		z M17.71,32.81h1.38c1.59,0,2.73-0.2,3.41-0.61c0.69-0.41,1.03-1.06,1.03-1.96c0-0.9-0.34-1.56-1.03-1.96
		c-0.69-0.41-1.82-0.61-3.41-0.61h-1.38V32.81z"
        />
        <path
          d="M53.86,37.7h-7.85l-6.02-9.34v9.34h-6.31V13.43h9.82c1.35,0,2.53,0.2,3.54,0.6c1.01,0.4,1.84,0.94,2.49,1.63
		c0.65,0.69,1.15,1.48,1.48,2.38c0.33,0.9,0.5,1.87,0.5,2.9c0,1.85-0.45,3.34-1.34,4.49c-0.89,1.15-2.21,1.93-3.94,2.33L53.86,37.7z
		 M39.99,24.28h1.19c1.24,0,2.2-0.26,2.87-0.77c0.67-0.51,1-1.26,1-2.22c0-0.97-0.33-1.71-1-2.22c-0.67-0.52-1.62-0.77-2.87-0.77
		h-1.19V24.28z"
        />
        <path d="M62.81,13.43V37.7H56.5V13.43H62.81z" />
        <path d="M81.7,18.78h-7.5v4.06h7.08v5.34H74.2v4.18h7.5v5.34H67.89V13.43H81.7V18.78z" />
        <path d="M100.15,18.78h-7.6v4.06h6.92v5.34h-6.92v9.53h-6.31V13.43h13.91V18.78z" />
        <path
          d="M129.73,33.49h-9.01l-1.45,4.22h-6.73l9.24-24.27h6.89l9.24,24.27h-6.73L129.73,33.49z M128.06,28.69l-2.83-8.08
		l-2.83,8.08H128.06z"
        />
        <path
          d="M154,19.65c-0.69-0.56-1.37-0.97-2.06-1.24c-0.69-0.27-1.35-0.4-2-0.4c-0.82,0-1.48,0.19-2,0.58
		c-0.51,0.39-0.77,0.89-0.77,1.51c0,0.43,0.13,0.78,0.39,1.06c0.26,0.28,0.6,0.52,1.01,0.72c0.42,0.2,0.89,0.38,1.42,0.53
		c0.53,0.15,1.05,0.31,1.56,0.48c2.06,0.69,3.57,1.6,4.52,2.75c0.96,1.15,1.43,2.65,1.43,4.49c0,1.24-0.21,2.37-0.63,3.38
		c-0.42,1.01-1.03,1.87-1.84,2.59c-0.8,0.72-1.79,1.28-2.96,1.67c-1.17,0.4-2.49,0.6-3.97,0.6c-3.07,0-5.91-0.91-8.53-2.74l2.7-5.09
		c0.94,0.84,1.88,1.46,2.8,1.87c0.92,0.41,1.84,0.61,2.74,0.61c1.03,0,1.8-0.24,2.3-0.71c0.5-0.47,0.76-1.01,0.76-1.61
		c0-0.36-0.07-0.68-0.19-0.95c-0.13-0.27-0.34-0.51-0.64-0.74c-0.3-0.23-0.69-0.43-1.17-0.63c-0.48-0.19-1.07-0.41-1.75-0.64
		c-0.82-0.26-1.62-0.54-2.4-0.85c-0.78-0.31-1.48-0.72-2.09-1.24c-0.61-0.51-1.11-1.16-1.48-1.95c-0.38-0.78-0.56-1.78-0.56-2.98
		c0-1.2,0.2-2.29,0.6-3.27c0.4-0.98,0.95-1.81,1.67-2.51c0.72-0.7,1.6-1.24,2.64-1.63c1.04-0.39,2.2-0.58,3.49-0.58
		c1.2,0,2.46,0.17,3.77,0.5s2.56,0.82,3.77,1.46L154,19.65z"
        />
        <path
          d="M174.8,19.65c-0.69-0.56-1.37-0.97-2.06-1.24c-0.69-0.27-1.35-0.4-2-0.4c-0.82,0-1.48,0.19-2,0.58
		c-0.51,0.39-0.77,0.89-0.77,1.51c0,0.43,0.13,0.78,0.39,1.06c0.26,0.28,0.6,0.52,1.01,0.72c0.42,0.2,0.89,0.38,1.42,0.53
		c0.53,0.15,1.05,0.31,1.56,0.48c2.06,0.69,3.57,1.6,4.52,2.75c0.96,1.15,1.43,2.65,1.43,4.49c0,1.24-0.21,2.37-0.63,3.38
		c-0.42,1.01-1.03,1.87-1.84,2.59c-0.8,0.72-1.79,1.28-2.96,1.67c-1.17,0.4-2.49,0.6-3.97,0.6c-3.07,0-5.91-0.91-8.53-2.74l2.7-5.09
		c0.94,0.84,1.88,1.46,2.8,1.87c0.92,0.41,1.84,0.61,2.74,0.61c1.03,0,1.8-0.24,2.3-0.71c0.5-0.47,0.76-1.01,0.76-1.61
		c0-0.36-0.07-0.68-0.19-0.95c-0.13-0.27-0.34-0.51-0.64-0.74c-0.3-0.23-0.69-0.43-1.17-0.63c-0.48-0.19-1.07-0.41-1.75-0.64
		c-0.82-0.26-1.62-0.54-2.4-0.85c-0.78-0.31-1.48-0.72-2.09-1.24c-0.61-0.51-1.11-1.16-1.48-1.95c-0.38-0.78-0.56-1.78-0.56-2.98
		c0-1.2,0.2-2.29,0.6-3.27c0.4-0.98,0.95-1.81,1.67-2.51c0.72-0.7,1.6-1.24,2.64-1.63c1.04-0.39,2.2-0.58,3.49-0.58
		c1.2,0,2.46,0.17,3.77,0.5s2.56,0.82,3.77,1.46L174.8,19.65z"
        />
        <path d="M196.07,18.78h-7.5v4.06h7.08v5.34h-7.08v4.18h7.5v5.34h-13.81V13.43h13.81V18.78z" />
        <path d="M209.82,18.78V37.7h-6.31V18.78h-5.18v-5.34H215v5.34H209.82z" />
      </g>
    </svg>
  );
};

export default LogoSephoraCollection;
