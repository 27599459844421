import React, { Fragment, useEffect, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

import MenuItem from "@components/NavigationBar/components/MenuItem";
import { HomeOutlined } from "@mui/icons-material";
import { Box, Collapse, List } from "@mui/material";
import type { SeasonsGlobalInformations } from "@src/types";
import { useAppSelector } from "@store/hooks";
import { selectSeasons } from "@store/season/season.selector";
import { SeasonalBrief } from "@utils/constants.utils";

const openedSeasonFormat = (year: string, seasonId: number) =>
  `${year}.${seasonId}`;

const pathNameEqualsToCleanName = (pathName: string, cleanName: string) =>
  pathName.slice(pathName.lastIndexOf("/") + 1, pathName.length) === cleanName;

const NavCatSeasonalBrief: React.FC = () => {
  const navigate = useNavigate();
  const [openedYears, setOpenedYears] = useState<string[]>([]);
  const [openedSeasons, setOpenedSeasons] = useState<string[]>([]);
  const seasons = useAppSelector(selectSeasons);

  const location = useLocation();

  const handleYearClick = (year: string) => {
    let newSelectedYears: string[] = [];
    if (openedYears.includes(year)) {
      newSelectedYears = openedYears.filter(
        (selectedYear) => selectedYear !== year,
      );
      const newSelectedSeasons = openedSeasons.filter(
        (selectedSeason) => !selectedSeason.includes(year),
      );
      setOpenedSeasons(newSelectedSeasons);
    } else {
      newSelectedYears = [...openedYears, year];
    }
    setOpenedYears(newSelectedYears);
  };

  const handleSeasonClick = (year: string, seasonId: number) => {
    let newSelectedSeasons: string[] = [];
    if (openedSeasons.includes(openedSeasonFormat(year, seasonId))) {
      newSelectedSeasons = openedSeasons.filter(
        (selectedSeason) =>
          selectedSeason !== openedSeasonFormat(year, seasonId),
      );
    } else {
      newSelectedSeasons = [
        ...openedSeasons,
        openedSeasonFormat(year, seasonId),
      ];
    }
    setOpenedSeasons(newSelectedSeasons);
  };

  const handleClickElement = (element: SeasonsGlobalInformations) => {
    const redirectWithParams = generatePath("season/:cleanName", {
      cleanName: element.cleanName,
    });
    navigate(redirectWithParams);
  };

  const isActive = (element: SeasonsGlobalInformations) =>
    pathNameEqualsToCleanName(location.pathname, element.cleanName);

  useEffect(() => {
    for (const { year, seasons: seasonsByType } of seasons) {
      for (const { seasonTypeId, elements } of seasonsByType) {
        const matchingElement = elements.find(({ cleanName }) =>
          pathNameEqualsToCleanName(location.pathname, cleanName),
        );

        if (matchingElement) {
          setOpenedYears([year]);
          setOpenedSeasons([openedSeasonFormat(year, seasonTypeId)]);
        }
      }
    }
  }, [location.pathname, seasons]);

  return (
    <>
      <MenuItem
        icon={<HomeOutlined color="secondary" />}
        label={SeasonalBrief}
        onClick={() => {
          navigate("/");
        }}
      />
      {seasons.map((item) => (
        <Box key={item.year} sx={{ ml: 2 }}>
          <MenuItem
            hasNestedRoutes
            label={item.year}
            onClick={() => {
              handleYearClick(item.year);
            }}
            isNestedMenuOpen={openedYears.includes(item.year)}
          />
          <Collapse
            in={openedYears.includes(item.year)}
            timeout="auto"
            unmountOnExit
          >
            <List sx={{ pl: 2 }}>
              {item.seasons.map((season) => (
                <Fragment key={season.seasonTypeId}>
                  <MenuItem
                    label={season.seasonTypeName}
                    hasNestedRoutes
                    onClick={() =>
                      handleSeasonClick(item.year, season.seasonTypeId)
                    }
                    isNestedMenuOpen={openedSeasons.includes(
                      openedSeasonFormat(item.year, season.seasonTypeId),
                    )}
                  />
                  <Collapse
                    in={openedSeasons.includes(
                      `${item.year}.${season.seasonTypeId}`,
                    )}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List sx={{ pl: 2 }}>
                      {season.elements.map((element) => (
                        <MenuItem
                          key={element.id}
                          label={element.name.toUpperCase()}
                          isActive={isActive(element)}
                          onClick={() => handleClickElement(element)}
                        />
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))}
            </List>
          </Collapse>
        </Box>
      ))}
    </>
  );
};

export default NavCatSeasonalBrief;
