import type { JwtAccessToken, Role } from "@src/types";
import { type Authority, RolesWithAuthorities } from "@src/types";
import { ENV } from "@utils/env.utils";

export const getRolesFromAccessToken = (
  accessToken: string,
): Role[] | undefined => {
  const accessTokenPayload = parseJwt(accessToken);
  // keep only env related roles
  const roles = accessTokenPayload?.briefassetgroups?.filter((role) =>
    (ENV === "prod" ? /.*-PROD-.+$/ : /.*-HPRD-.+$/).test(role),
  );
  // remove same roles
  return Array.from(new Set(roles?.map(mapOktaRoleToBriefAssetRole)));
};

export const hasAuthority = (roles: Role[], authority: Authority): boolean => {
  return roles.some((role) => RolesWithAuthorities[role]?.includes(authority));
};

/**
 * [JWT format] ex: DM-App-BriefAsset-HPRD-SC-Gestion-Admins-SL
 * [JWT format] ex: DM-App-BriefAsset-HPRD-SC-MarketingProduct-Admins-SL
 */
export const mapOktaRoleToBriefAssetRole = (briefAssetRole: string): Role => {
  // Role is always in 6th position
  return briefAssetRole.split("-")[5].toUpperCase() as Role;
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1])) as JwtAccessToken;
  } catch (e) {
    return null;
  }
};
