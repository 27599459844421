import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";

import { ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import App from "@src/App";
import "@src/index.css";
import theme from "@src/theme";
import store, { persistor } from "@store/store";

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { en } from "lang";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <IntlProvider locale="en" messages={en}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={5}>
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </IntlProvider>
    </PersistGate>
  </Provider>,
);
