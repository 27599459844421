import React, { type FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";
import { AssetsList } from "@pages/ProductPage/components";
import { useLazyGetProductQuery } from "@store/api/product";
import { useLazyGetSeasonQuery } from "@store/api/season";
import { selectCredentials } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";

import ProductPageHeader from "pages/ProductPage/components/ProductPageHeader/ProductPageHeader";

const ProductPage: FC = () => {
  const { productId } = useParams();
  const credentials = useAppSelector(selectCredentials);
  const [getProduct, { data: product }] = useLazyGetProductQuery();
  const [getSeason] = useLazyGetSeasonQuery();

  useEffect(() => {
    if (productId && credentials) {
      getProduct({ id: parseInt(productId) });
    }
  }, [productId, credentials]);

  useEffect(() => {
    if (productId) {
      // todo - should not trigger when delete product from ProductPageHeader component
      getProduct({ id: parseInt(productId) });
      // re-set to false otherwise we will be able to refresh only once
    }
  }, [productId]);

  useEffect(() => {
    if (product) {
      getSeason({ cleanName: product.seasonCleanName });
    }
  }, [product]);

  return (
    <Box>
      <ProductPageHeader product={product} />
      {product && <AssetsList product={product} />}
    </Box>
  );
};

export default ProductPage;
