import React from "react";
import type { FieldValues } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import GeographicScopeComponent from "@components/DataDisplay/GeographicScope";
import { FormLabel, Grid } from "@mui/material";
import { GeographicScope } from "@utils/data/enums/generals.enum";

interface Props<T extends FieldValues> {
  fieldName: keyof T;
  value: GeographicScope;
  onChange: (scope: GeographicScope) => void;
}

const GeographicScopeSelector = <T extends FieldValues>({
  value,
  onChange,
  fieldName,
}: Props<T>) => {
  const options: GeographicScope[] = [
    GeographicScope.CHINA,
    GeographicScope.NORTHAMERICA,
  ];

  return (
    <>
      <FormLabel>
        <FormattedMessage
          id={`form.label.${fieldName as string}`}
          defaultMessage={fieldName as string}
        />
      </FormLabel>
      <Grid item flexDirection="row" display="flex" gap={2} mt={2}>
        {options.map((scope) => (
          <GeographicScopeComponent
            key={scope}
            scope={scope}
            selected={scope === value}
            onClick={() => onChange(scope)}
          />
        ))}
      </Grid>
    </>
  );
};

export default GeographicScopeSelector;
