import React, { type FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useNestedIntl from "@src/hooks/useNestedIntl";
import type { WaitingForApprovalRequest } from "@src/types";
import { useWaitingForApprovalAssetMutation } from "@store/api/asset";

interface Props {
  onClose: () => void;
  id: number;
  isWaitingForApproval?: boolean;
  defaultValues?: WaitingForApprovalRequest;
}

const WaitingForApprovalModal: FC<Props> = ({
  onClose,
  id,
  isWaitingForApproval = false,
  defaultValues,
}) => {
  const intl = useIntl();
  const nestedIntl = useNestedIntl();
  const [waitingForApprovalAsset, { isLoading, isSuccess }] =
    useWaitingForApprovalAssetMutation();
  const {
    register,
    watch,
    trigger,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<WaitingForApprovalRequest>({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      whyIsWaitingForApproval: defaultValues?.whyIsWaitingForApproval
        ? defaultValues.whyIsWaitingForApproval
        : "",
    },
  });

  const onSubmit = async () => {
    if (isWaitingForApproval) {
      await trigger();
    }
    if (isValid) {
      waitingForApprovalAsset({
        id,
        whyIsWaitingForApproval,
        isWaitingForApproval,
      });
    }
  };

  const whyIsWaitingForApproval = watch("whyIsWaitingForApproval");

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      {isWaitingForApproval && (
        <Box my={4}>
          <TextField
            label={intl.formatMessage({
              id: "form.label.whyIsWaitingForApproval",
            })}
            type="text"
            fullWidth
            error={!!errors.whyIsWaitingForApproval}
            {...register("whyIsWaitingForApproval", {
              required: nestedIntl(
                { id: "common.rules.required" },
                { fieldName: "common.reason.label" },
              ),
            })}
          />
          {errors.whyIsWaitingForApproval && (
            <FormHelperText error>
              {errors.whyIsWaitingForApproval?.message}
            </FormHelperText>
          )}
        </Box>
      )}

      <Typography>
        {isWaitingForApproval ? (
          <FormattedMessage id="common.approval.modal.desc.waiting.label" />
        ) : (
          <FormattedMessage id="common.approval.modal.desc.approved.label" />
        )}
      </Typography>

      <Grid container my={2}>
        <Grid item display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage id="common.cancel" />
          </Button>

          <Button disabled={isLoading} type="submit" variant="contained">
            <FormattedMessage id="common.confirm" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default WaitingForApprovalModal;
