import React from "react";

import { Box } from "@mui/material";

interface Props {
  color: string;
}

const Dot: React.FC<Props> = ({ color }) => (
  <Box
    component="span"
    sx={{
      width: 10,
      height: 10,
      backgroundColor: color,
      borderRadius: "50%",
      display: "inline-block",
      marginLeft: 1,
    }}
  />
);

export default Dot;
