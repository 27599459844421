import { BaseDto } from "@src/dto/base.dto";
import type {
  AssetFileValueRequest,
  AssetPutRequest,
  AssetValue,
} from "@src/types";

export class AssetPutRequestDto extends BaseDto {
  new_tag?: { value: AssetValue[] };
  comment?: AssetValue;
  nb_of_shades_to_shoot?: AssetValue;
  nb_of_carnations?: AssetValue;
  skus?: AssetValue;
  name_of_shades?: AssetValue;
  comment_from_marketing_product?: AssetValue;
  geographic_scope?: { value: AssetValue[] };
  ppage?: { value: AssetValue[] };
  digital_animation?: { value: AssetValue[] };
  media_regional_context?: AssetValue;
  media_traditional_tv_dooh_ooh?: { value: AssetValue[] };
  media_digital?: { value: AssetValue[] };
  permanent_instore?: { value: AssetValue[] };
  temporary_instore?: { value: AssetValue[] };
  bench_visuals?: AssetFileValueRequest[];

  constructor(asset: AssetPutRequest) {
    super();
    this.new_tag = asset.assetSimpleField.new_tag;
    this.comment = asset.assetSimpleField.comment;
    this.nb_of_shades_to_shoot = asset.assetSimpleField.nb_of_shades_to_shoot;
    this.nb_of_carnations = asset.assetSimpleField.nb_of_carnations;
    this.skus = asset.assetSimpleField.skus;
    this.name_of_shades = asset.assetSimpleField.name_of_shades;
    this.comment_from_marketing_product =
      asset.assetSimpleField.comment_from_marketing_product;
    this.geographic_scope = asset.assetSimpleField.geographic_scope;
    this.ppage = asset.assetSimpleField.ppage;
    this.digital_animation = asset.assetSimpleField.digital_animation;
    this.media_regional_context = asset.assetSimpleField.media_regional_context;
    this.media_traditional_tv_dooh_ooh =
      asset.assetSimpleField.media_traditional_tv_dooh_ooh;
    this.media_digital = asset.assetSimpleField.media_digital;
    this.permanent_instore = asset.assetSimpleField.permanent_instore;
    this.temporary_instore = asset.assetSimpleField.temporary_instore;
    this.media_traditional_tv_dooh_ooh =
      asset.assetSimpleField.media_traditional_tv_dooh_ooh;
    if (asset.bench_visuals) {
      this.bench_visuals = asset.bench_visuals;
    }
  }
}
