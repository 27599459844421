import type { FileGetRequest, FileGetResponse } from "@src/types";
import { defaultApi } from "@store/api/api";
import endpoints from "@store/api/endpoints";

export const fileApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getCharter: build.query<FileGetResponse, FileGetRequest>({
      query: ({ fileName }) => {
        return {
          url: endpoints.file.withCharterFileName(fileName),
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetCharterQuery } = fileApi;
