import React, { type FC } from "react";
import type { FieldValues } from "react-hook-form";

import CancelModal from "@components/Modals/CancelModal";
import DeleteModal from "@components/Modals/DeleteModal";
import DeliverModal from "@components/Modals/DeliverModal";
import DuplicateModal from "@components/Modals/DuplicateModal";
import WaitingForApprovalModal from "@components/Modals/WaitingForApprovalModal";
import type { DeliverRequest, WaitingForApprovalRequest } from "@src/types";
import { MODAL_ACTION_TYPES } from "@src/types";

interface Props {
  id: number;
  actionType: MODAL_ACTION_TYPES;
  onClose: () => void;
  isAsset?: boolean;
  defaultValues?: FieldValues;
}

const GenericProductModal: FC<Props> = ({
  id,
  actionType,
  onClose,
  isAsset,
  defaultValues,
}) => {
  switch (actionType) {
    case MODAL_ACTION_TYPES.DUPLICATE_PRODUCT: {
      return <DuplicateModal onClose={onClose} id={id} />;
    }
    case MODAL_ACTION_TYPES.CANCEL: {
      return (
        <CancelModal id={id} isAsset={isAsset} isCancel onClose={onClose} />
      );
    }
    case MODAL_ACTION_TYPES.UNDO_CANCEL: {
      return <CancelModal id={id} isAsset={isAsset} onClose={onClose} />;
    }
    case MODAL_ACTION_TYPES.SET_WAITING_FOR_APPROVAL: {
      return (
        <WaitingForApprovalModal
          onClose={onClose}
          id={id}
          isWaitingForApproval
          {...(defaultValues && {
            defaultValues: defaultValues as WaitingForApprovalRequest,
          })}
        />
      );
    }
    case MODAL_ACTION_TYPES.UNSET_WAITING_FOR_APPROVAL: {
      return <WaitingForApprovalModal onClose={onClose} id={id} />;
    }
    case MODAL_ACTION_TYPES.DELIVER_ASSET: {
      return <DeliverModal onClose={onClose} id={id} isDelivered />;
    }
    case MODAL_ACTION_TYPES.UNDELIVER_ASSET: {
      return (
        <DeliverModal
          onClose={onClose}
          id={id}
          {...(defaultValues && {
            defaultValues: defaultValues as DeliverRequest,
          })}
        />
      );
    }
    case MODAL_ACTION_TYPES.DELETE_ASSET:
    case MODAL_ACTION_TYPES.DELETE_PRODUCT: {
      return <DeleteModal onClose={onClose} id={id} isAsset={isAsset} />;
    }
    case MODAL_ACTION_TYPES.NONE:
    default:
      return null;
  }
};

export default GenericProductModal;
