import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { type FieldPath, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import Carousel from "@components/Carousel/Carousel";
import Dropzone from "@components/Dropzone";
import IconEqual from "@components/Icon/IconEqual";
import IconMultiplication from "@components/Icon/IconMultiplication";
import AssetFormField from "@components/Inputs/AssetFormField";
import GenericProductModal from "@components/Modals/GenericProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import ProductActions from "@components/ProductActions";
import {
  CancelScheduleSendOutlined,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Box,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import {
  ACCEPT_FILES_TYPES,
  AssetContentFieldStatus,
  type AssetFileValueRequest,
  Authority,
  MODAL_ACTION_TYPES,
} from "@src/types";
import {
  useDeleteBenchVisualAssetMutation,
  usePutAssetMutation,
} from "@store/api/asset";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import { selectProduct } from "@store/product/product.selector";
import {
  selectCanModifySeasonData,
  selectedSeason,
} from "@store/season/season.selector";
import { colors } from "@theme/theme";
import { DEBOUNCE_DELAY } from "@utils/constants.utils";
import {
  AssetTagToColorMapping,
  getDirtyValues,
  getOnlyValueFromAsset,
} from "@utils/fonctions.utils";

import dayjs from "dayjs";
import type { Asset, AssetForm } from "types/api/asset";
import { useDebounce } from "use-debounce";

interface IProductsElementListProps {
  asset: Asset;
}

const AssetsElementList: FC<IProductsElementListProps> = ({ asset }) => {
  const intl = useIntl();
  const season = useAppSelector(selectedSeason);
  const { id: productId } = useAppSelector(selectProduct) || {};

  const canUpdateAsset = useAppSelector((state) =>
    selectCanModifySeasonData(state, Authority.UPDATE_ASSET),
  );
  const canUpdateDoneByImageTeam = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.UPDATE_ASSET_IMAGE_TEAM),
  );

  const [isOpenCollaspe, setIsOpenCollapse] = useState<boolean>(false);
  const [useStatus, setUseStatus] = useState<boolean>(false);

  const [deleteBenchVisuals] = useDeleteBenchVisualAssetMutation();
  const [putAsset] = usePutAssetMutation();

  const {
    isPopupOpen,
    actionType,
    handleOpenPopup,
    handleClosePopup,
    defaultValues,
  } = usePopupHandler();

  const {
    register,
    control,
    watch,
    setValue,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<AssetForm>();

  const formValues = watch();
  const [debouncedFormValues] = useDebounce(formValues, DEBOUNCE_DELAY);

  // 1. auto submit data when form data changes
  useEffect(() => {
    if (isDirty && Object.keys(dirtyFields).length > 0) {
      onSubmit();
    }
  }, [debouncedFormValues]);

  // 2. backend update request
  const onSubmit = () => {
    if (productId) {
      const fieldsToUpdate = getDirtyValues(
        dirtyFields,
        formValues,
        getOnlyValueFromAsset(asset),
        useStatus,
      );

      const filteredBenchVisuals = formValues.bench_visuals.value
        .filter(
          (item): item is AssetFileValueRequest => item.value instanceof File,
        )
        .map((item) => {
          return {
            value: item.value,
            status: item.status,
          };
        });

      if (
        Object.keys(fieldsToUpdate).length > 0 ||
        filteredBenchVisuals.length > 0
      ) {
        putAsset({
          assetSimpleField: fieldsToUpdate,
          bench_visuals: filteredBenchVisuals,
          id: asset.id,
        });
      }
    }
  };

  // 3. rebase form values on backend response
  useEffect(() => {
    reset(getOnlyValueFromAsset(asset));
  }, [asset]);

  useEffect(() => {
    if (season) {
      setUseStatus(dayjs().isAfter(season.deadlineNoDef));
    }
  }, [season]);

  const handleUpdateArrayValue = (
    fieldName: FieldPath<AssetForm>,
    value: AssetFileValueRequest,
  ) => {
    if (Array.isArray(watch(fieldName))) {
      setValue(fieldName, [...(watch(fieldName) as Array<any>), value], {
        shouldDirty: true,
      });
    } else {
      setValue(fieldName, [value], { shouldDirty: true });
    }
  };

  const handleDeleteBenchVisuals = (index: number) => {
    if (bench_visuals && productId) {
      deleteBenchVisuals({
        file: bench_visuals.value[index]?.value.name,
        id: asset.id,
      });
    }
  };

  const isTagSelected = (tag: string) =>
    formValues.new_tag.value.map(({ value }) => value).includes(tag);

  const { bench_visuals } = watch();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, value: number) => {
    setTabValue(value);
  };

  const _renderTab = () => {
    if (tabValue === 0) {
      return (
        <Grid container gap={2} display="flex" flexDirection="column">
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.ppage}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.digital_animation}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
        </Grid>
      );
    } else if (tabValue === 1) {
      return (
        <Grid container gap={2} display="flex" flexDirection="column">
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.media_regional_context}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.media_traditional_tv_dooh_ooh}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.media_digital}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
        </Grid>
      );
    } else if (tabValue === 2) {
      return (
        <Grid container gap={2} display="flex" flexDirection="column">
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.permanent_instore}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
          <Grid item>
            <AssetFormField
              control={control}
              watch={watch}
              setValue={setValue}
              field={asset.content.temporary_instore}
              register={register}
              useStatus={useStatus}
            />
          </Grid>
        </Grid>
      );
    }
  };

  if (!Object.keys(formValues).length) {
    return <></>;
  }

  return (
    <>
      <Paper sx={{ p: 1, m: 1 }}>
        <form>
          <Grid container display="flex" justifyContent="space-between">
            <Grid container item gap={2} width="auto" alignItems="center">
              <Grid item>
                <IconButton
                  onClick={() => setIsOpenCollapse((prevState) => !prevState)}
                >
                  {isOpenCollaspe ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
              <Grid item>
                <Typography>{asset.type}</Typography>
              </Grid>
              {asset.isCancel && (
                <Tooltip title={asset.whyIsCancel}>
                  <Chip
                    color="error"
                    variant="outlined"
                    icon={<CancelOutlinedIcon color="error" />}
                    label={intl.formatMessage({ id: "asset.status.cancelled" })}
                  />
                </Tooltip>
              )}
              {asset.isWaitingForApproval && (
                <Tooltip title={asset.whyIsWaitingForApproval}>
                  <Chip
                    {...(canUpdateAsset && {
                      onClick: () => {
                        handleOpenPopup(
                          MODAL_ACTION_TYPES.SET_WAITING_FOR_APPROVAL,
                          {
                            whyIsWaitingForApproval:
                              asset.whyIsWaitingForApproval,
                          },
                        );
                      },
                    })}
                    icon={<AutorenewOutlinedIcon />}
                    label={intl.formatMessage({
                      id: "asset.status.waiting-for-approval",
                    })}
                  />
                </Tooltip>
              )}
              {!asset.isDelivered && (
                <Tooltip title={asset.whyNotDelivered}>
                  <Chip
                    {...(canUpdateDoneByImageTeam && {
                      onClick: () => {
                        handleOpenPopup(MODAL_ACTION_TYPES.UNDELIVER_ASSET, {
                          whyNotDelivered: asset.whyNotDelivered,
                        });
                      },
                    })}
                    icon={<CancelScheduleSendOutlined />}
                    label={intl.formatMessage({
                      id: "asset.status.not-delivered",
                    })}
                  />
                </Tooltip>
              )}
            </Grid>
            {canUpdateAsset && (
              <Grid item>
                <ProductActions
                  id={asset.id}
                  isAsset
                  actions={[
                    {
                      action: asset.isWaitingForApproval
                        ? MODAL_ACTION_TYPES.UNSET_WAITING_FOR_APPROVAL
                        : MODAL_ACTION_TYPES.SET_WAITING_FOR_APPROVAL,
                      defaultValues: {
                        whyIsWaitingForApproval: asset.whyIsWaitingForApproval,
                      },
                    },
                    {
                      action: asset.isCancel
                        ? MODAL_ACTION_TYPES.UNDO_CANCEL
                        : MODAL_ACTION_TYPES.CANCEL,
                      defaultValues: {
                        whyIsCancel: asset.whyIsCancel,
                      },
                    },
                    { action: MODAL_ACTION_TYPES.DELETE_ASSET },
                  ]}
                />
              </Grid>
            )}
          </Grid>
          <Box
            my={2}
            ml={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" gap={2}>
              {[
                asset.content.digital,
                asset.content.infographic,
                asset.content.print,
                asset.content.media,
              ].map((field) => (
                <AssetFormField
                  key={field.name}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  field={field}
                  register={register}
                  useStatus={useStatus}
                />
              ))}
            </Stack>
            <Stack direction="row" gap={2}>
              {asset.content.new_tag.possibleValues?.map((tag) => (
                <Chip
                  key={tag}
                  size="small"
                  {...(isTagSelected(tag)
                    ? {
                        variant: "filled",
                        sx: {
                          backgroundColor: colors[AssetTagToColorMapping[tag]],
                          color: colors.white,
                        },
                        onDelete: () =>
                          setValue(
                            "new_tag.value",
                            formValues.new_tag.value.filter(
                              (value) => value.value !== tag,
                            ),
                            { shouldDirty: true },
                          ),
                      }
                    : {
                        variant: "outlined",
                        onClick: () =>
                          setValue(
                            "new_tag.value",
                            [
                              ...formValues.new_tag.value,
                              {
                                value: tag,
                                status: AssetContentFieldStatus.NULL,
                              },
                            ],
                            { shouldDirty: true },
                          ),
                      })}
                  label={tag}
                />
              ))}
            </Stack>
          </Box>
          <Box mx={2}>
            <Collapse in={isOpenCollaspe} timeout="auto" unmountOnExit>
              <Grid
                item
                display="flex"
                flexDirection="column"
                gap={2}
                width="100%"
              >
                <Grid item width="100%">
                  <AssetFormField
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    field={asset.content.comment}
                    register={register}
                    useStatus={useStatus}
                  />
                </Grid>
                <Grid item display="flex" flex={1}>
                  <AssetFormField
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    field={asset.content.comment_from_marketing_product}
                    register={register}
                    useStatus={useStatus}
                  />
                </Grid>
              </Grid>
              {dayjs().isAfter(season?.deadlineDef) && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={asset.isDelivered}
                        disabled={!canUpdateDoneByImageTeam}
                        onClick={() => {
                          handleOpenPopup(
                            asset.isDelivered
                              ? MODAL_ACTION_TYPES.UNDELIVER_ASSET
                              : MODAL_ACTION_TYPES.DELIVER_ASSET,
                            { whyNotDelivered: asset.whyNotDelivered },
                          );
                        }}
                      />
                    }
                    label={intl.formatMessage({
                      id: "asset.status.done-by-image-team",
                    })}
                  />
                </Grid>
              )}
              <Grid container gap={2} mt={2}>
                <Grid item gap={2} display="flex" flexDirection="row">
                  <Carousel
                    title={intl.formatMessage({
                      id: "form.label.bench_visuals.value",
                    })}
                    nbElementsPerView={3}
                    images={bench_visuals.value}
                    handleClickDelete={(deletedIndex) => {
                      handleDeleteBenchVisuals(deletedIndex);
                      const newArray = bench_visuals.value?.filter(
                        (image, index) => index !== deletedIndex,
                      );
                      setValue("bench_visuals.value", newArray);
                    }}
                  />
                  <Dropzone
                    handleUploadFile={(file) =>
                      handleUpdateArrayValue("bench_visuals.value", {
                        value: file[0],
                        status: AssetContentFieldStatus.NULL,
                      })
                    }
                    accept={ACCEPT_FILES_TYPES.BENCH_VISUALS}
                  />
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>

                <Grid item gap={2} display="flex" flexDirection="column">
                  <Grid item>
                    <AssetFormField
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      field={asset.content.geographic_scope}
                      register={register}
                      useStatus={useStatus}
                    />
                  </Grid>
                  <Grid item display="flex" flexDirection="row" gap={4}>
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        field={asset.content.skus}
                        register={register}
                        useStatus={useStatus}
                      />
                    </Grid>
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        field={asset.content.name_of_shades}
                        register={register}
                        useStatus={useStatus}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    flexDirection="row"
                    gap={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        field={asset.content.nb_of_carnations}
                        register={register}
                        useStatus={useStatus}
                      />
                    </Grid>
                    <Grid item>
                      <IconMultiplication fontSize="small" />
                    </Grid>
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        field={asset.content.nb_of_shades_to_shoot}
                        register={register}
                        useStatus={useStatus}
                      />
                    </Grid>
                    <Grid item>
                      <IconEqual fontSize="large" />
                    </Grid>
                    <Grid item>
                      <AssetFormField
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        field={asset.content.total_nb_of_assets}
                        register={register}
                        useStatus={useStatus}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Tabs value={tabValue} onChange={handleTabChange} sx={{ mt: 1 }}>
                {[
                  intl.formatMessage({ id: "asset.detail.tab.digital" }),
                  intl.formatMessage({ id: "asset.detail.tab.media" }),
                  intl.formatMessage({ id: "asset.detail.tab.print" }),
                ].map((tab) => (
                  <Tab key={tab} label={tab} />
                ))}
              </Tabs>
              <Box mt={2}>{_renderTab()}</Box>
            </Collapse>
          </Box>
        </form>
      </Paper>
      <ModalLayout
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={intl.formatMessage({
          id: `common.modal.generic-modal.actions.${actionType}`,
        })}
      >
        <GenericProductModal
          id={asset.id}
          actionType={actionType}
          onClose={handleClosePopup}
          isAsset
          defaultValues={defaultValues}
        />
      </ModalLayout>
    </>
  );
};

export default AssetsElementList;
