import React, { type FC } from "react";
import type { FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";

import ButtonGrouped from "@components/ButtonGrouped/ButtonGrouped";
import GenericProductModal from "@components/Modals/GenericProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import type { MODAL_ACTION_TYPES } from "@src/types";

interface Props {
  id: number;
  actions: { action: MODAL_ACTION_TYPES; defaultValues?: FieldValues }[];
  isAsset?: boolean;
}

const ProductPageHeader: FC<Props> = ({ id, actions, isAsset }) => {
  const intl = useIntl();
  const {
    isPopupOpen,
    actionType,
    handleOpenPopup,
    handleClosePopup,
    defaultValues,
  } = usePopupHandler();

  return (
    <>
      <ButtonGrouped
        options={actions.map((action) => ({
          label: intl.formatMessage({
            id: `common.modal.generic-modal.actions.${action.action}`,
          }),
          onClick: () => handleOpenPopup(action.action, action.defaultValues),
        }))}
      />

      <ModalLayout
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={intl.formatMessage({
          id: `common.modal.generic-modal.actions.${actionType}`,
        })}
      >
        <GenericProductModal
          id={id}
          actionType={actionType}
          onClose={handleClosePopup}
          isAsset={isAsset}
          defaultValues={defaultValues}
        />
      </ModalLayout>
    </>
  );
};

export default ProductPageHeader;
