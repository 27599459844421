import { createSlice } from "@reduxjs/toolkit";
import type { AxisDetails } from "@src/types";
import { axisApi } from "@store/api/axis";

interface AxisState {
  axisType: AxisDetails[];
}

const initialState: AxisState = {
  axisType: [],
};

const axisSlice = createSlice({
  name: "axis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      axisApi.endpoints.getAxis.matchFulfilled,
      (state, { payload }) => {
        state.axisType = payload;
      },
    );
  },
});

export default axisSlice.reducer;
