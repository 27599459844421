import type { AssetPutRequestDto } from "@src/dto/assetPutRequest.dto";
import type { ProductPutRequestDto } from "@src/dto/productPutRequest.dto";
import type { SeasonPutRequestDto } from "@src/dto/seasonPutRequest.dto";
import type { AssetPostRequest } from "@src/types";

import type { ProductPostBodySimpleField } from "types/api/product";

export const createFormDataSeason = (data: SeasonPutRequestDto) => {
  const { merchAnnexes, ...body } = data;
  const formData = new FormData();
  if (merchAnnexes && merchAnnexes.length > 0) {
    merchAnnexes?.forEach((file) => {
      formData.append(`merchAnnexes`, file);
    });
  }
  Object.entries(body).forEach(([key, value]) =>
    formData.append(key, `${value}`),
  );

  return formData;
};

export const createFormDataProduct = (
  data: ProductPostBodySimpleField | ProductPutRequestDto,
  seasonCleanName: string,
) => {
  const { assortmentFile, packshots, additionalPhotos, ...body } = data;
  const formData = new FormData();
  if (packshots && packshots.length > 0) {
    packshots?.forEach((file) => {
      formData.append("packshots", file);
    });
  }
  if (additionalPhotos && additionalPhotos.length > 0) {
    additionalPhotos?.forEach((file) => {
      formData.append("additionalPhotos", file);
    });
  }

  if (assortmentFile && assortmentFile instanceof Blob) {
    formData.append(
      "assortmentFile",
      assortmentFile,
      `${seasonCleanName}_assortment.xlsx`,
    );
  }

  Object.entries(body).forEach(([key, value]) =>
    formData.append(key, `${value}`),
  );

  return formData;
};

export const createFormDataAsset = (data: AssetPutRequestDto) => {
  const { bench_visuals, ...body } = data;
  const formData = new FormData();

  if (bench_visuals && bench_visuals.length > 0) {
    bench_visuals?.forEach((file) => {
      formData.append("bench_visuals", file.value);
    });
  }
  formData.append("content", JSON.stringify(body));

  return formData;
};

export const createPostFormDataAsset = (data: AssetPostRequest) => {
  const { bench_visuals, content, ...body } = data;
  const formData = new FormData();
  if (bench_visuals && bench_visuals.length > 0) {
    bench_visuals?.forEach((file) => {
      formData.append(`bench_visuals`, file);
    });
  }
  formData.append("content", JSON.stringify(content));
  Object.entries(body).forEach(([key, value]) =>
    formData.append(key, `${value}`),
  );

  return formData;
};
