import { AssetPostRequestDto } from "@src/dto/assetPostRequest.dto";
import { AssetPutRequestDto } from "@src/dto/assetPutRequest.dto";
import type {
  Asset,
  AssetDeleteRequest,
  AssetPostRequest,
  AssetPutRequest,
  CancelRequest,
  DeliverRequest,
  FileAssetDeleteRequest,
  WaitingForApprovalRequest,
} from "@src/types";
import { API_TAGS, METHODS } from "@src/types";
import { defaultApi } from "@store/api/api";
import endpoints from "@store/api/endpoints";
import { createFormDataAsset, createPostFormDataAsset } from "@utils/api.utils";

export const assetApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    postAsset: build.mutation<Asset, AssetPostRequest>({
      query: (asset) => {
        const assetPostBody = new AssetPostRequestDto(asset);
        return {
          url: endpoints.asset.all,
          method: METHODS.POST,
          body: createPostFormDataAsset(assetPostBody),
        };
      },
      invalidatesTags: [API_TAGS.PRODUCT],
    }),
    putAsset: build.mutation<Asset, AssetPutRequest>({
      query: (asset) => {
        const assetPutBody = new AssetPutRequestDto(asset);
        return {
          url: endpoints.asset.withId(asset.id),
          method: METHODS.PUT,
          body: createFormDataAsset(assetPutBody),
        };
      },
      invalidatesTags: [API_TAGS.PRODUCT],
    }),
    cancelAsset: build.mutation<Asset, CancelRequest>({
      query: (assetReference) => {
        const { id, ...body } = assetReference;
        return {
          url: endpoints.asset.cancel(id),
          method: METHODS.PUT,
          body: body,
        };
      },
      invalidatesTags: [API_TAGS.PRODUCT],
    }),
    waitingForApprovalAsset: build.mutation<Asset, WaitingForApprovalRequest>({
      query: (assetReference) => {
        const { id, ...body } = assetReference;
        return {
          url: endpoints.asset.waitingForApproval(id),
          method: METHODS.PUT,
          body: body,
        };
      },
      invalidatesTags: [API_TAGS.PRODUCT],
    }),
    deliverAsset: build.mutation<Asset, DeliverRequest>({
      query: (assetReference) => {
        const { id, ...body } = assetReference;
        return {
          url: endpoints.asset.deliver(id),
          method: METHODS.PUT,
          body,
        };
      },
      invalidatesTags: [API_TAGS.PRODUCT],
    }),
    deleteAsset: build.mutation<void, AssetDeleteRequest>({
      query: ({ id }) => {
        return {
          url: endpoints.asset.withId(id),
          method: METHODS.DELETE,
        };
      },
      invalidatesTags: [API_TAGS.PRODUCT],
    }),
    deleteBenchVisualAsset: build.mutation<Asset, FileAssetDeleteRequest>({
      query: (arg) => {
        const { id, file } = arg;
        return {
          url: endpoints.asset.deleteFile(id, "bench_visuals"),
          method: METHODS.DELETE,
          params: { file },
        };
      },
      invalidatesTags: [API_TAGS.PRODUCT],
    }),
  }),
  overrideExisting: false,
});

export const {
  usePostAssetMutation,
  usePutAssetMutation,
  useCancelAssetMutation,
  useWaitingForApprovalAssetMutation,
  useDeliverAssetMutation,
  useDeleteAssetMutation,
  useDeleteBenchVisualAssetMutation,
} = assetApi;
