import { AssetContentFieldStatus, type AssetPostForm } from "@src/types";
import { Levels, ProductStatus } from "@utils/data/enums/generals.enum";

export const SeasonalBrief = "Seasonal Brief";

export const X_ID_TOKEN = "x-id-token";

export const X_API_KEY = "x-api-key";

export const X_ACCESS_TOKEN = "x-access-token";

export const OKTA_API_KEY = "xapikey";

export const LevelOfActivationOption = [
  { value: Levels.BIGBET, label: "Big bet" },
  { value: Levels.STRATEGIC, label: "Strategic" },
  { value: Levels.CLASSIC, label: "Standard" },
  { value: Levels.PILLARS, label: "Pillar" },
];

export const DISPLAY_DATE_FORMAT = "DD/MM/YYYY";

export const StatusOption = [
  {
    value: ProductStatus.ONE_SHOT,
    label: "One shot",
  },
  {
    value: ProductStatus.CATALOGUE,
    label: "Catalogue",
  },
  {
    value: ProductStatus.CATALOGUE_1_YEAR,
    label: "Catalogue 1 year",
  },
];

export const initAsset: Partial<AssetPostForm> = {
  new_tag: [],
  comment: { value: "", status: AssetContentFieldStatus.NULL },
  nb_of_shades_to_shoot: { value: "", status: AssetContentFieldStatus.NULL },
  nb_of_carnations: { value: "", status: AssetContentFieldStatus.NULL },
  total_nb_of_assets: { value: "", status: AssetContentFieldStatus.NULL },
  skus: { value: "", status: AssetContentFieldStatus.NULL },
  name_of_shades: { value: "", status: AssetContentFieldStatus.NULL },
  comment_from_marketing_product: {
    value: "",
    status: AssetContentFieldStatus.NULL,
  },
  geographic_scope: [],
  ppage: [],
  digital_animation: [],
  media_regional_context: { value: "", status: AssetContentFieldStatus.NULL },
  media_traditional_tv_dooh_ooh: [],
  media_digital: [],
  permanent_instore: [],
  temporary_instore: [],
};

export const possibleValues = {
  geographic_scope: [
    "WW",
    "NAM",
    "LATAM",
    "EU",
    "MEAST",
    "SEA",
    "CN",
    "WW exc. CN",
    "WW exc. NAM",
  ],
  ppage: [
    "1500",
    "2000",
    "INFOGRAPHIC",
    "INFOGRAPHIC user test or clinical test",
    "INFOGRAPHIC key information",
    "INFOGRAPHIC Battle",
    "INFOGRAPHIC Routine",
    "MP4",
  ],
  digital_animation: [
    "EME Window 2880x892",
    "EME Underbanner 686x434",
    "EME App card 890x1135",
    "EME Tile 400x700",
    "EME Brandspace intro 900x575",
    "EME Marketing banner 792x244",
    "EME Beauty tips 684x742",
    "EME Brandorama 508x244",
    "SEA Billboard Desk 1125x500",
    "SEA Billboard Mobile 1062x798",
    "SEA Billboard App 1080x810",
    "SEA Brandpage 1125x300",
    "LATAM Window Desk 1280x450",
    "LATAM Window Mobile 600x450",
    "LATAM App Homepage 600x300",
    "LATAM Brandpace 689x264",
  ],
  media_traditional_tv_dooh_ooh: [
    "Outdoor GB",
    "Outdoor FR",
    "MAIN VIDEO – GB // 20 SEC FOR TV",
    "MAIN VIDEO – FR // 20 SEC FOR TV",
    "MAGAZINE PRINT A4",
  ],
  media_digital: [
    "MAIN VIDEO – GB // 15 SEC FOR DIGITAL AND SOCIAL",
    "MAIN VIDEO – GB // 10 SEC FOR SOCIAL AND DOH",
    "MAIN VIDEO – GB // 6 SEC FOR DIGITAL",
    "MAIN VIDEO – FR // 15 SEC FOR DIGITAL AND SOCIAL",
    "MAIN VIDEO – FR // 10 SEC FOR SOCIAL AND DOH",
    "MAIN VIDEO – FR // 6 SEC FOR DIGITAL",
    "EUR DIGITAL BANNERS GB",
    "EUR DIGITAL BANNERS FR",
    "US DIGITAL BANNERS - GB",
    "SOCIAL MEDIA – BRANDED MOTION ASSETS",
    "SOCIAL MEDIA MOVIES – UGV CONTENT CREATORS ASSETS",
    "SOCIAL MEDIA MOVIES – UGV CONTENT CREATORS ASSETS",
    "SOCIAL MEDIA – BRANDED STATIC ASSETS",
    "EMAILING",
    "SOCIAL MEDIA STATIC FEED",
    "SOCIAL MEDIA IG STORY",
  ],
  permanent_instore: [
    "EDG, Caisson, 485mm x 1180mm x 220mm",
    "EDG, Joues, 382mm x 1350,6mm",
    "EDG, Header + Habillage du fond, 585mm x 1405mm",
    "EDG, Visuel marque, 318mm x 827mm",
    "EDG, Colonne, 240mm x 1005mm",
    "C2,  , 1900mm x 972mm",
    "C3,  , 2847mm x 972mm",
    "Beauty Hub Standard,  , 820mm x 725mm",
    "Beauty Hub Premium,  , 820mm x 800mm",
    "Cashtill,  , 30,1mm x 8,2mm",
    "CN, EDG, Central visual, 340mm x 980mm",
    "CN, EDG, Box shelf talker, 440mm x 640mm x 220mm",
    "CN, EDG, 2 Wings visual, 380mm x 1375mm",
    "C2, Top visual, 1890mm x 215mm",
    "C2, 1st Shelf visual, 1890mm x 313mm",
    "C2, 2nd Shelf visual, 1890mm x 435mm",
    "C2, Bottom visual , 1890mm x 380mm",
    "C2 Animated, Video, 2160 x 3840",
    "C2 Animated, Background visual, 1346mm x 816,5mm",
    "C2 Animated, Bottom visual , 945mm x 380mm",
  ],
  temporary_instore: [
    "EME, MUP, MIS15, Single Visual, 129,7mm x 292,5mm",
    "EME, MUP, MIS15 & MIS15 2.0, Double Visual, 283,95mm x 292,5mm",
    "EME, MUP, MIS15 & MIS15 2.0, Triple Visual, 438,2mm x 292,5mm",
    "EME, MUP, MIS15 & MIS15 2.0, Peel Off, 152,7x314,8mm",
    "EME + SEA + CN, MUP, COLOR21, Visual Podium, 580x374mm",
    "EME + SEA + CN, MUP, COLOR21, Zone Focus, 296x230mm",
    "EME + SEA + CN, MUP, COLOR21, Module Visual, 146,4mm x 163mm",
    "EME + SEA + CN, MUP, COLOR21, Post-It, 46mm x 71,5mm",
    "SEA + CN, MUP, NSC, NSC Screen, 1884x520mm",
    "SEA + CN, MUP, NSC, NSC Visual, 752x282mm",
    "NAM, MUP, COLOR, Main Focus Unit, Main Panel, 605,02x478,02mm",
    "NAM, MUP, COLOR, Main Focus Unit, Merchandiser, 316,33x477,26mm",
    "NAM, MUP, COLOR, Main Focus Unit, SUB Focus Visual, 148,84x316,48mm",
    "NAM, MUP, COLOR, CENTER VISUAL, 492,125x492,125mm",
    "NAM, ACCESS, COLOR, COVER VISUAL, 46,3296 cm x 85,2424 cm",
    "US/CAN/QUE, MUP, TIP , TIP , 429,125x 429,125mm",
    "US/CAN/QUE, MUP, FLEET, FLEET, 946,15x1841,5mm",
    "US/CAN/QUE, MUP, FLEET, FLEET, 946,15x1598,6125mm",
    "US/CAN/QUE, MUP, FLEET, FLEET, 946,15x926,2115mm",
    "US/CAN/QUE, MUP, FLEET, FLEET, 926,15x1226,0072mm",
    "US/CAN/QUE, MUP, FLEET, FLEET, 926,15x1171,575mm",
    "US ONLY, MUP, FLEET, FLEET, 946,15x1353,0072mm",
    "US ONLY, MUP, FLEET, FLEET, 946,15x1130,3mm",
    "US ONLY, MUP, FLEET, FLEET, 946,15x971,55mm",
    "NAM, MUP, PLUTO, MFU VISUAL, 624,586mm x 403,352mm",
    "NAM, MUP, PLUTO, VERTICAL VISUAL, 193,802mm x1166,622",
    "NAM, MUP, PLUTO, SUB FOCUS VISUAL, 101,6mm x 203,2mm / 156,464mm x 203,2mm",
    "NAM, MUP, PLUTO, TOP SHELF MFU + MERCHANDISER, 234,95mm x 357,124mm",
    "NAM, MUP, PLUTO, TOP SHELF MFU + TESTER, 368,3mm x 357,124mm",
    "EU + SEA + UK, ACCESS, Pro Brush Endcap, Pro Brush Endcap, 287x168mm",
    "EME + UK , ACCESS, Premium C2, Premium C2 Didactic Visual, 223 x 245 mm",
    "EME + UK , ACCESS, Premium C2, Premium C2 Visual, 403mm x 172mm",
    "EU + UK, ACCESS, Standard C1, Standard C1 Visual, 103,6 x 179 mm",
    "EU + UK, ACCESS, Standard C2, Standard C2 Visual, 207,2 x 179 mm",
    "EU, ACCESS, Brush & Tools Endcap, Brush & Tools Endcap, 167x146,75mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML ACCESS & TOOLS, Visual lightbox, 690 mm x 226 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML ACCESS & TOOLS, Visual column , 191 mm x 391 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML ACCESS & TOOLS, Visual on shelf (TBC), 164 mm x 215 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML ESSENTIAL BRUSHES , Visual lightbox, 690 mm x 226 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML ESSENTIAL BRUSHES , Visual column , 191 mm x139 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES , Visual lightbox, 690 mm x 226 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES , Visual column , 191 mm x & 391 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES & PRO ENDCAP, Visual stock FOUNDATION FACE, 226 mm x 224 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES & PRO ENDCAP, Visual stock COLOR FACE, 226 mm x 224 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES , Visual stock EYES, 226 mm x 224 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML ESSENTIAL BRUSHES & ACCESS TOOLS , Visual lightbox Essential brushes , 470 mm x 210 mm (TBC)",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML ESSENTIAL BRUSHES & ACCESS TOOLS , Visual lightbox Accessories & Tools , 470 mm x 210 mm (TBC)",
    "EU + UK + SEA + MEAST + SEA, ACCESS, EDG PRO , Visual lightbox, 575 mm x 215 mm",
    "EU , ACCESS, EDG CORNER ESSENTIAL BRUSHES & ACCESS TOOLS, Visual lightbox Essential brushes , 575 mm x 215 mm",
  ],
};

export const DEBOUNCE_DELAY = 750;
