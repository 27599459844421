import React from "react";
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
  type Path,
  type PathValue,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";

import Carousel from "@components/Carousel/Carousel";
import Dropzone from "@components/Dropzone";
import FileUploader from "@components/FileUploader/FileUploader";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  type TextFieldProps,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FormListInput } from "@src/components";
import { ACCEPT_FILES_TYPES, FIELD_TYPES, type FieldConfig } from "@src/types";
import { DISPLAY_DATE_FORMAT } from "@utils/constants.utils";

import dayjs from "dayjs";

interface Props<T extends FieldValues> {
  field: FieldConfig<T>;
  setValue: UseFormSetValue<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  watch: UseFormWatch<T>;
}

const GenericFormField = <T extends FieldValues>({
  field,
  setValue,
  register,
  control,
  watch,
}: Props<T>) => {
  const intl = useIntl();
  const watchedValue = watch(field.fieldName);

  const handleUpdateArrayValue = (
    fieldName: FieldPath<T>,
    value: PathValue<T, Path<T>>,
  ) => {
    if (Array.isArray(value)) {
      if (Array.isArray(watchedValue)) {
        setValue(
          fieldName,
          [...(watchedValue as Array<File>), ...value] as PathValue<T, Path<T>>,
          {
            shouldDirty: true,
          },
        );
      } else {
        setValue(fieldName, value, { shouldDirty: true });
      }
    } else {
      if (Array.isArray(watchedValue)) {
        setValue(
          fieldName,
          [...(watchedValue as Array<File>), value] as PathValue<T, Path<T>>,
          {
            shouldDirty: true,
          },
        );
      } else {
        setValue(fieldName, [value] as PathValue<T, Path<T>>, {
          shouldDirty: true,
        });
      }
    }
  };

  const _renderField = (field: FieldConfig<T>) => {
    const { fieldName, mandatory, fieldType, ...props } = field;
    const fieldLabel = intl.formatMessage({
      id: `form.label.${fieldName}`,
      defaultMessage: fieldName,
    });
    switch (fieldType) {
      case FIELD_TYPES.SELECT:
        return (
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl fullWidth>
                <InputLabel>
                  {fieldLabel}
                  {mandatory && " *"}
                </InputLabel>
                <Select
                  label={`${fieldLabel}${mandatory ? " *" : ""}`}
                  error={!!error}
                  {...register(fieldName, {
                    ...(mandatory && {
                      required: intl.formatMessage(
                        { id: "common.rules.required" },
                        { fieldName: fieldLabel },
                      ),
                    }),
                  })}
                  onChange={onChange}
                  value={value}
                  disabled={field.options.length === 0}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        );
      case FIELD_TYPES.MULTI_SELECT:
        return (
          <FormListInput
            fieldName={fieldName}
            watch={watch}
            setValue={setValue}
            options={field.options}
            withStatus={field.withStatus}
          />
        );
      case FIELD_TYPES.FILE:
        return (
          <>
            <FormLabel
              sx={{ mr: 1 }}
            >{`${fieldLabel}${mandatory ? " *" : ""}`}</FormLabel>
            <FileUploader
              limit={field.limit || 1}
              accept={field.accept || ACCEPT_FILES_TYPES.ASSORTMENT_FILE}
              handleUploadFiles={(files) =>
                setValue(fieldName, files as PathValue<T, Path<T>>, {
                  shouldDirty: true,
                })
              }
              mandatory={mandatory}
              {...props}
            />
          </>
        );
      case FIELD_TYPES.DATE: {
        return (
          <Controller
            control={control}
            {...register(fieldName, {
              validate: {
                ...(field.minDate && {
                  minDate: (value) => {
                    return (
                      dayjs(value)
                        .startOf("date")
                        .isSameOrAfter(dayjs(field.minDate).startOf("date")) ||
                      intl.formatMessage({ id: "common.rules.dates.future" })
                    );
                  },
                }),
                ...(field.maxDate && {
                  maxDate: (value) =>
                    dayjs(value)
                      .startOf("date")
                      .isSameOrBefore(dayjs(field.maxDate).startOf("date")) ||
                    intl.formatMessage(
                      { id: "common.rules.dates.before" },
                      {
                        dateMin: field.label,
                        dateMax: field.maxDate.format(DISPLAY_DATE_FORMAT),
                      },
                    ),
                }),
              },
              ...(mandatory && {
                required: intl.formatMessage(
                  { id: "common.rules.required" },
                  { fieldName: fieldLabel },
                ),
              }),
            })}
            render={({ field: { ...controller }, fieldState: { error } }) => (
              <>
                <DatePicker
                  sx={{ width: "100%" }}
                  {...controller}
                  {...(field.minDate && {
                    minDate: field.minDate,
                  })}
                  {...(field.maxDate && {
                    maxDate: field.maxDate,
                  })}
                  format={DISPLAY_DATE_FORMAT}
                  label={`${fieldLabel}${mandatory ? " *" : ""}`}
                />
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </>
            )}
          />
        );
      }
      case FIELD_TYPES.CAROUSEL:
        return (
          <>
            <FormLabel>{`${fieldLabel}${mandatory ? " *" : ""}`}</FormLabel>
            <Dropzone
              accept={field.accept}
              multiple={field.multiple}
              handleUploadFile={(file) =>
                handleUpdateArrayValue(
                  fieldName,
                  file[0] as PathValue<T, Path<T>>,
                )
              }
              {...(field.limit &&
                field.images && {
                  disabled: field.images.length >= field.limit,
                })}
            />
            {!!field.images?.length && (
              <Carousel
                images={field.images}
                handleClickDelete={(deletedIndex) => {
                  const newArray = (
                    field.images as PathValue<T, Path<T>>
                  )?.filter(
                    (image: any, index: number) => index !== deletedIndex,
                  );
                  setValue(fieldName, newArray, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          </>
        );
      case FIELD_TYPES.BOOLEAN:
        return (
          <FormControlLabel
            disabled={field.disabled}
            control={
              <Checkbox {...register(fieldName)} {...field} size="small" />
            }
            label={fieldLabel}
          />
        );
      case FIELD_TYPES.INPUT_NUMBER:
      case FIELD_TYPES.INPUT_TEXT:
        return (
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                type={
                  field.fieldType === FIELD_TYPES.INPUT_TEXT ? "text" : "number"
                }
                label={`${fieldLabel}${mandatory ? " *" : ""}`}
                error={!!error?.message}
                {...register(fieldName, {
                  ...(mandatory && {
                    required: intl.formatMessage(
                      { id: "common.rules.required" },
                      { fieldName: fieldLabel },
                    ),
                  }),
                })}
                InputLabelProps={{
                  shrink: !!value || value === 0,
                }}
                {...(props as TextFieldProps)}
                onChange={(event) => {
                  if (field.onChange) {
                    field.onChange(event);
                  }
                  onChange(event);
                }}
                helperText={error?.message}
              />
            )}
          />
        );
      default:
        return <></>;
    }
  };

  return _renderField(field);
};

export default GenericFormField;
