import React from "react";

import { SvgIcon } from "@mui/material";
import type { SvgIconProps } from "@mui/material";

const IconEqual: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
      >
        <path
          fill="currentColor"
          d="M62 10.571L53.428 2L32 23.429L10.571 2L2 10.571L23.428 32L2 53.429L10.571 62L32 40.571L53.428 62L62 53.429L40.57 32z"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconEqual;
