import { createSlice } from "@reduxjs/toolkit";
import type { SeasonResponse, SeasonsOrderByYears } from "@src/types";
import { seasonApi } from "@store/api/season";

interface SeasonState {
  seasons: SeasonsOrderByYears[];
  selectedSeason?: SeasonResponse;
}

const initialState: SeasonState = {
  seasons: [],
};

const seasonSlice = createSlice({
  name: "season",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      seasonApi.endpoints.getSeason.matchFulfilled,
      (state, { payload }) => {
        state.selectedSeason = payload;
      },
    );
    builder.addMatcher(
      seasonApi.endpoints.getSeasons.matchFulfilled,
      (state, { payload }) => {
        state.seasons = payload;
      },
    );
  },
});

export default seasonSlice.reducer;
