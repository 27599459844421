import React, { type FC, Fragment, useState } from "react";

import { StyledDeleteImageBtnContainer } from "@components/Carousel/Carousel.styled";
import {
  DeleteOutline,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  FormLabel,
  IconButton,
  MobileStepper,
} from "@mui/material";
import type {
  AssetFileValueResponse,
  FileComponentGetDetails,
} from "@src/types";

export interface CarouselProps {
  title?: string;
  images?:
    | string[]
    | File[]
    | FileComponentGetDetails[]
    | AssetFileValueResponse[]
    | (File | FileComponentGetDetails)[];
  nbElementsPerView?: number;
  disabled?: boolean;
  handleClickDelete?: (index: number) => void;
}

const Carousel: FC<CarouselProps> = ({
  title,
  images = [],
  disabled,
  handleClickDelete,
  nbElementsPerView = 1,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = Math.ceil(images.length / nbElementsPerView);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const removeImage = (index: number) => {
    if (activeStep >= images.length - 1 && activeStep > 0) {
      handleBack();
    }
    if (handleClickDelete) {
      handleClickDelete(index);
    }
  };

  const getImageUrl = (image: (typeof images)[number]) => {
    if (typeof image === "string") {
      return image;
    } else if ("value" in image) {
      if (image.value instanceof File) {
        return URL.createObjectURL(image.value); // asset - images that just got uploaded
      } else if ("url" in image.value) {
        return image.value.url; // asset - images from api response
      }
    } else if (image instanceof File) {
      return URL.createObjectURL(image);
    } else if ("url" in image) {
      return image.url; // images from api response
    }
  };

  if (images.length === 0) {
    return <></>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      {title && <FormLabel>{title}</FormLabel>}
      <Box
        sx={{
          width: 200 * Math.min(nbElementsPerView, images.length),
          p: 0,
          display: "flex",
          flex: Math.min(nbElementsPerView, images.length),
        }}
      >
        {Array.from(Array(nbElementsPerView)).map((_elt, index) => (
          <Fragment key={index}>
            {!!images[activeStep + index] && (
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  height: 120,
                  justifyContent: "center",
                }}
              >
                {!disabled && (
                  <StyledDeleteImageBtnContainer>
                    <IconButton onClick={() => removeImage(activeStep + index)}>
                      <DeleteOutline />
                    </IconButton>
                  </StyledDeleteImageBtnContainer>
                )}
                <img
                  src={getImageUrl(images[activeStep + index])}
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    overflow: "hidden",
                    flex: 1,
                  }}
                />
                {nbElementsPerView !== index + 1 && (
                  <Divider orientation="vertical" sx={{ mx: 1 }} />
                )}
              </Box>
            )}
          </Fragment>
        ))}
      </Box>
      <MobileStepper
        backButton={
          <IconButton
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <KeyboardArrowLeft />
          </IconButton>
        }
        position="static"
        activeStep={activeStep}
        variant="dots"
        nextButton={
          <IconButton
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight />
          </IconButton>
        }
        steps={maxSteps}
      />
    </Box>
  );
};

export default Carousel;
