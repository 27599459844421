import React, { useState } from "react";
import type {
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";

import { CloseOutlined } from "@mui/icons-material";
import UndoIcon from "@mui/icons-material/Undo";
import type { ChipOwnProps } from "@mui/material";
import {
  Autocomplete,
  type AutocompleteChangeReason,
  Chip,
  TextField,
} from "@mui/material";
import useNestedIntl from "@src/hooks/useNestedIntl";
import { AssetContentFieldStatus, type AssetValue } from "@src/types";
import { AssetStatusToColorMapping } from "@utils/fonctions.utils";

export interface IFormListInput<T extends FieldValues> {
  fieldName: Path<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  mandatory?: boolean;
  options: string[];
  disabled?: boolean;
  withStatus?: boolean;
}

const FormListInput = <T extends FieldValues>({
  fieldName,
  watch,
  setValue,
  mandatory = false,
  options = [],
  disabled,
  withStatus,
}: IFormListInput<T>) => {
  const nestedIntl = useNestedIntl();

  const watchedField = watch(fieldName) as AssetValue[];

  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState<string>("");

  const handleAddChip = (value: string) => {
    setValue(
      fieldName,
      [
        ...watchedField,
        {
          value: value,
          status: withStatus
            ? AssetContentFieldStatus.CREATED
            : AssetContentFieldStatus.NULL,
        },
      ] as PathValue<T, Path<T>>,
      {
        shouldDirty: true,
      },
    );
  };

  const handleSaveEditChip = (chipIndex: number) => {
    const newWatchedField = watchedField;
    newWatchedField.splice(chipIndex, 1, {
      value: editValue,
      status: withStatus
        ? AssetContentFieldStatus.MODIFIED
        : AssetContentFieldStatus.NULL,
    });
    setValue(fieldName, newWatchedField as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
    setEditingIndex(null);
  };

  const handleEditChip = (chipIndex: number) => {
    setEditingIndex(chipIndex);
    setEditValue(watchedField[chipIndex].value);
  };

  const handleDeleteChip = (chipIndex: number) => {
    const newWatchedField = watchedField;
    if (withStatus) {
      newWatchedField.splice(chipIndex, 1, {
        value: watchedField[chipIndex].value,
        status:
          watchedField[chipIndex].status === AssetContentFieldStatus.DELETED
            ? AssetContentFieldStatus.NULL
            : AssetContentFieldStatus.DELETED,
      });
    } else {
      newWatchedField.splice(chipIndex, 1);
    }
    setValue(fieldName, newWatchedField as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
  };

  const handleChange = (
    _: React.SyntheticEvent,
    value: string[],
    reason: AutocompleteChangeReason,
  ) => {
    switch (reason) {
      case "createOption":
      case "selectOption":
        handleAddChip(value[value.length - 1]);
        return;
      case "clear":
        watchedField.forEach((chip, index) => {
          if (chip.status !== AssetContentFieldStatus.DELETED) {
            handleDeleteChip(index);
          }
        });
        return;
    }
  };

  const getChipStatus = (value: string) =>
    watchedField.find((val) => val.value === value)?.status ||
    AssetContentFieldStatus.NULL;

  return (
    <Autocomplete
      renderInput={(params) => (
        <TextField
          {...params}
          label={`${nestedIntl({ id: "common.add.fieldName" }, { fieldName: `form.label.${fieldName}` })}${mandatory ? " *" : ""}`}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          const status = withStatus
            ? getChipStatus(option)
            : AssetContentFieldStatus.NULL;

          let color: ChipOwnProps["color"];
          if (fieldName === "ppage.value" && !/^\d+$/.test(option)) {
            color = "info";
          } else {
            color = AssetStatusToColorMapping[status];
          }

          if (editingIndex === index) {
            return (
              <TextField
                key={index}
                size="small"
                value={editValue}
                disabled={disabled}
                onChange={(e) => setEditValue(e.target.value)}
                onBlur={() => {
                  handleSaveEditChip(index);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSaveEditChip(index);
                }}
                autoFocus
              />
            );
          }

          return (
            <Chip
              key={key}
              label={option}
              variant="outlined"
              color={color}
              deleteIcon={
                status === AssetContentFieldStatus.DELETED ? (
                  <UndoIcon />
                ) : (
                  <CloseOutlined />
                )
              }
              {...tagProps}
              disabled={disabled}
              onClick={() => handleEditChip(index)}
              onDelete={() => handleDeleteChip(index)}
            />
          );
        })
      }
      onChange={handleChange}
      value={watchedField?.map((value) => value.value)}
      getOptionLabel={(option) => option}
      multiple
      freeSolo
      filterSelectedOptions
      disabled={disabled}
      options={options}
    />
  );
};

export default FormListInput;
