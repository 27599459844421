import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { defaultApi } from "@store/api/api";
import axisReducer from "@store/axis/axis.reducer";
import categoryReducer from "@store/category/category.reducer";
import middlewares from "@store/middlewares";
import productReducer from "@store/product/product.reducer";
import seasonReducer from "@store/season/season.reducer";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "store/auth/auth.reducer";
import common from "store/common/common.reducer";

const persistConfig = {
  key: "auth",
  storage,
};

const reducer = combineReducers({
  auth: persistReducer(persistConfig, auth),
  common,
  [defaultApi.reducerPath]: defaultApi.reducer,
  season: seasonReducer,
  axis: axisReducer,
  product: productReducer,
  category: categoryReducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
