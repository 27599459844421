export interface JwtAccessToken {
  ver: number;
  jti: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  cid: number;
  uid: number;
  scp: string[];
  auth_time: number;
  xapikey: string;
  sub: string;
  briefassetgroups: string[];
  api_groups: string[];
}

export enum Role {
  ADMINS = "ADMINS",
  IMAGE = "IMAGE",
  MARKETINGPRODUCT = "MARKETINGPRODUCT",
  DIGITAL = "DIGITAL",
  MOP = "MOP",
  TRAINING = "TRAINING",
}

export enum Authority {
  READ_ALL_FIELDS = "READ_ALL_FIELDS",
  CREATE_SEASONAL_BRIEF = "CREATE_SEASONAL_BRIEF",
  UPDATE_SEASONAL_BRIEF = "UPDATE_SEASONAL_BRIEF",
  UPDATE_SEASONAL_BRIEF_DATES = "UPDATE_SEASONAL_BRIEF_DATES", // todo
  CREATE_PRODUCT_BRIEF = "CREATE_PRODUCT_BRIEF",
  UPDATE_PRODUCT_BRIEF = "UPDATE_PRODUCT_BRIEF",
  CREATE_ASSET = "CREATE_ASSET",
  UPDATE_ASSET = "UPDATE_ASSET",
  UPDATE_ASSET_IMAGE_TEAM = "UPDATE_ASSET_IMAGE_TEAM",
  DOWNLOAD_BRIEF_ASSET = "DOWNLOAD_BRIEF_ASSET",
}

export const RolesWithAuthorities: Record<Role, Authority[]> = {
  [Role.MOP]: [
    Authority.READ_ALL_FIELDS,
    Authority.CREATE_SEASONAL_BRIEF,
    Authority.UPDATE_SEASONAL_BRIEF,
    Authority.UPDATE_SEASONAL_BRIEF_DATES,
    Authority.CREATE_PRODUCT_BRIEF,
    Authority.UPDATE_PRODUCT_BRIEF,
    Authority.CREATE_ASSET,
    Authority.UPDATE_ASSET,
    Authority.UPDATE_ASSET_IMAGE_TEAM,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.DIGITAL]: [
    Authority.READ_ALL_FIELDS,
    Authority.UPDATE_SEASONAL_BRIEF,
    Authority.UPDATE_SEASONAL_BRIEF_DATES,
    Authority.CREATE_PRODUCT_BRIEF,
    Authority.UPDATE_PRODUCT_BRIEF,
    Authority.CREATE_ASSET,
    Authority.UPDATE_ASSET,
    Authority.UPDATE_ASSET_IMAGE_TEAM,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.MARKETINGPRODUCT]: [
    Authority.READ_ALL_FIELDS,
    Authority.CREATE_SEASONAL_BRIEF,
    Authority.UPDATE_SEASONAL_BRIEF,
    Authority.CREATE_PRODUCT_BRIEF,
    Authority.UPDATE_PRODUCT_BRIEF,
    Authority.CREATE_ASSET,
    Authority.UPDATE_ASSET,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.IMAGE]: [
    Authority.READ_ALL_FIELDS,
    Authority.UPDATE_SEASONAL_BRIEF_DATES,
    Authority.UPDATE_PRODUCT_BRIEF,
    Authority.UPDATE_ASSET_IMAGE_TEAM,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.ADMINS]: [
    Authority.READ_ALL_FIELDS,
    Authority.CREATE_SEASONAL_BRIEF,
    Authority.UPDATE_SEASONAL_BRIEF,
    Authority.UPDATE_SEASONAL_BRIEF_DATES,
    Authority.CREATE_PRODUCT_BRIEF,
    Authority.UPDATE_PRODUCT_BRIEF,
    Authority.CREATE_ASSET,
    Authority.UPDATE_ASSET,
    Authority.UPDATE_ASSET_IMAGE_TEAM,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.TRAINING]: [Authority.READ_ALL_FIELDS],
};
