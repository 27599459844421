import { createSlice } from "@reduxjs/toolkit";
import { getRolesFromAccessToken } from "@utils/auth.utils";

import type { Credentials, Role } from "types";

export interface UserState {
  credentials?: Credentials;
  roles?: Role[];
}

const initialState: UserState = {
  credentials: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }: { payload: Credentials }) => {
      state.credentials = payload;
      state.roles = getRolesFromAccessToken(payload.accessToken);
    },
    removeCredentials: (state) => {
      state.credentials = undefined;
    },
  },
});

export const { setUser, removeCredentials } = authSlice.actions;

export default authSlice.reducer;
