import React, { type FC } from "react";

import { SvgIcon, type SvgIconProps } from "@mui/material";

const Lipstick: FC<SvgIconProps> = ({ color, ...props }) => {
  return (
    <SvgIcon inheritViewBox {...props}>
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <g transform="translate(0,512) scale(0.1,-0.1)">
          <path
            d="M2095 5109 c-46 -28 -44 -1 -45 -871 0 -795 -1 -828 -18 -828 -10 0
        -29 -12 -43 -26 l-24 -26 -3 -314 -3 -314 -54 0 c-43 0 -60 -5 -82 -24 l-28
    -24 -3 -191 c-3 -161 -6 -191 -18 -191 -8 0 -26 -11 -39 -25 l-25 -24 0 -1095
    c0 -602 3 -1102 6 -1111 18 -45 16 -45 844 -45 828 0 826 0 844 45 3 9 6 509
    6 1111 l0 1095 -25 24 c-13 14 -31 25 -39 25 -12 0 -15 30 -18 191 l-3 191
    -28 24 c-22 19 -39 24 -82 24 l-54 0 -3 314 -3 314 -24 26 c-14 14 -32 26 -42
    26 -17 0 -18 29 -21 468 l-4 467 -27 88 c-103 333 -356 579 -678 657 -107 26
    -237 35 -264 19z m245 -190 c131 -36 223 -91 330 -199 74 -73 105 -113 137
    -175 45 -88 83 -201 83 -247 0 -28 -1 -29 -37 -23 -99 16 -145 30 -229 71
    -191 91 -313 250 -375 489 -31 119 -32 118 91 84z m11 -594 c131 -123 280
    -193 462 -217 l87 -12 0 -343 0 -343 -340 0 -340 0 0 531 0 531 39 -49 c21
    -26 62 -71 92 -98z m639 -1340 l0 -255 -430 0 -430 0 0 255 0 255 430 0 430 0
    0 -255z m168 -552 l-3 -128 -595 0 -595 0 -3 128 -3 127 601 0 601 0 -3 -127z
    m82 -1283 l0 -980 -680 0 -680 0 0 980 0 980 680 0 680 0 0 -980z"
            stroke="black"
            strokeWidth="180"
          />
          <path
            d="M2932 1940 c-12 -11 -24 -31 -27 -43 -3 -12 -4 -259 -3 -549 l3 -526
    24 -26 c33 -35 83 -35 116 -1 l25 24 0 546 0 546 -25 24 c-30 31 -82 33 -113
    5z"
            stroke="black"
            strokeWidth="10"
          />
          <path
            d="M2952 585 c-41 -18 -55 -53 -50 -133 3 -47 9 -68 26 -84 30 -30 84
        -29 117 2 22 21 25 31 25 96 0 62 -3 77 -22 98 -30 32 -56 38 -96 21z"
            stroke="black"
            strokeWidth="10"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Lipstick;
