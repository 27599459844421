import { defaultApi } from "@store/api/api";
import endpoints from "@store/api/endpoints";

import type { AssetTypology } from "types/api/export";

export const exportApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    exportPPTX: build.query<
      any,
      { seasonCleanName: string; typology: AssetTypology }
    >({
      query: ({ seasonCleanName, typology }) => {
        return {
          url: endpoints.export.withSeasonCleanName(seasonCleanName),
          params: { typology: typology },
          responseHandler: async (response) => {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            // TODO get it from content disposition
            a.download = `seasonal-brief-${seasonCleanName}-${typology}.pptx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          },
          cache: "no-cache",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyExportPPTXQuery } = exportApi;
