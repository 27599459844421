import React, { type FC } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { LoginCallback } from "@okta/okta-react";
import ProductPage from "@pages/ProductPage";
import { HomePage, SeasonPage } from "@src/pages";
import { Root, SecureRoute, SecurityContainer } from "@src/routes";

import useSnackbar from "hooks/useSnackbar";

const App: FC = () => {
  useSnackbar();
  const router = createBrowserRouter([
    {
      path: "/",
      element: <SecurityContainer />,
      children: [
        {
          path: "/login/callback",
          element: <LoginCallback />,
        },
        {
          path: "/",
          element: <SecureRoute />,
          children: [
            {
              path: "/",
              element: <Root />,
              children: [
                {
                  path: "/",
                  element: <HomePage />,
                },
                {
                  path: "/season/:seasonCleanName",
                  element: <SeasonPage />,
                },
                {
                  path: "/product/:productId",
                  element: <ProductPage />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
