import React, { type FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";
import { ProductsList, SeasonPageHeader } from "@pages/SeasonPage/components";
import { useLazyGetProductsWithSeasonQuery } from "@store/api/product";
import { useLazyGetSeasonQuery } from "@store/api/season";
import { selectCredentials } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";

const SeasonPage: FC = () => {
  const { seasonCleanName } = useParams();
  const credentials = useAppSelector(selectCredentials);

  const [getSeason, { data: season, isLoading: isLoadingSeason }] =
    useLazyGetSeasonQuery();
  const [getProducts, { data: products, isLoading: isLoadingProducts }] =
    useLazyGetProductsWithSeasonQuery();

  useEffect(() => {
    if (seasonCleanName && credentials) {
      getSeason({ cleanName: seasonCleanName });
      getProducts({ cleanName: seasonCleanName });
    }
  }, [seasonCleanName, credentials]);

  useEffect(() => {
    if (seasonCleanName) {
      getSeason({ cleanName: seasonCleanName });
      getProducts({ cleanName: seasonCleanName });
    }
  }, []);

  return (
    <Box>
      <SeasonPageHeader season={season} isLoading={isLoadingSeason} />
      <ProductsList products={products} isLoading={isLoadingProducts} />
    </Box>
  );
};

export default SeasonPage;
