import type { FC } from "react";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import AddProductModal from "@components/Modals/AddProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import { AddOutlined } from "@mui/icons-material";
import { Button, Grid, Skeleton, Stack } from "@mui/material";
import ProductListItem from "@pages/SeasonPage/components/ProductsList/components/ProductListItem";
import type { ProductGroupedByLevel } from "@src/types";
import { Authority } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";

interface Props {
  products?: ProductGroupedByLevel[];
  isLoading?: boolean;
}

const ProductsList: FC<Props> = ({ products, isLoading }) => {
  const intl = useIntl();

  const [isModalOpen, setOpenModal] = useState(false);

  const canAddProduct = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.CREATE_PRODUCT_BRIEF),
  );

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      {canAddProduct && (
        <Grid container display="flex">
          <Grid item sx={{ ml: "auto", mr: 4, my: 4 }}>
            <Button
              startIcon={<AddOutlined />}
              onClick={handleOpenModal}
              variant="contained"
            >
              <FormattedMessage id="product.button.add" />
            </Button>
          </Grid>
        </Grid>
      )}
      {isLoading && (
        <Stack direction="column" gap={4} mx={4}>
          {[...Array(2)].map((_, i) => (
            <Skeleton key={i} variant="rectangular" width="100%" height={240} />
          ))}
        </Stack>
      )}
      <Stack direction="column" gap={4} mx={4}>
        {products?.map((product) => (
          <ProductListItem product={product} key={product.level} />
        ))}
      </Stack>

      {canAddProduct && (
        <ModalLayout
          open={isModalOpen}
          onClose={handleCloseModal}
          title={intl.formatMessage({ id: "product.modal.title" })}
        >
          <AddProductModal />
        </ModalLayout>
      )}
    </>
  );
};

export default ProductsList;
