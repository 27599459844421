import OktaAuth, { type OktaAuthOptions } from "@okta/okta-auth-js";

import {
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
  OKTA_LOGOUT_URL,
  OKTA_REDIRECT_URI,
} from "utils/env.utils";

const config: OktaAuthOptions = {
  issuer: OKTA_ISSUER,
  redirectUri: OKTA_REDIRECT_URI,
  logoutUrl: OKTA_LOGOUT_URL,
  clientId: OKTA_CLIENT_ID,
  scopes: ["openid", "profile", "briefassetgroups"],
};

export const oktaAuth = new OktaAuth(config);
