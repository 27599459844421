import { BaseDto } from "@src/dto/base.dto";
import type { AssetPostRequest, AssetValue } from "@src/types";
import type { CommonAssetType } from "@utils/enum.utils";

export class AssetPostRequestDto extends BaseDto {
  type: CommonAssetType;
  productId: number;
  bench_visuals?: File[];
  content: {
    new_tag?: AssetValue[];
    comment?: AssetValue;
    nb_of_shades_to_shoot?: AssetValue;
    nb_of_carnations?: AssetValue;
    skus?: AssetValue;
    name_of_shades?: AssetValue;
    comment_from_marketing_product?: AssetValue;
    geographic_scope?: AssetValue[];
    ppage?: AssetValue[];
    digital_animation?: AssetValue[];
    media_regional_context?: AssetValue;
    media_traditional_tv_dooh_ooh?: AssetValue[];
    media_digital?: AssetValue[];
    permanent_instore?: AssetValue[];
    temporary_instore?: AssetValue[];
  };

  constructor(asset: AssetPostRequest) {
    const { type, productId, bench_visuals, content } = asset;
    super();
    this.type = type;
    this.content = content;
    this.productId = productId;
    this.bench_visuals = bench_visuals;
  }
}
