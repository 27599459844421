import React, { type FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import GeographicScopeComponent from "@components/DataDisplay/GeographicScope";
import Dot from "@components/Dot/Dot";
import ProductActions from "@components/ProductActions";
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import type { ProductPreviewDetails } from "@src/types";
import { MODAL_ACTION_TYPES } from "@src/types";
import { colors } from "@theme/theme";
import { convertTimeZoneInDate } from "@utils/fonctions.utils";

interface Props {
  productPreview: ProductPreviewDetails;
}

const ProductCard: FC<Props> = ({ productPreview }) => {
  const intl = useIntl();
  const navigation = useNavigate();

  const handleClickViewDetails = () => {
    navigation(`/product/${productPreview.id}`);
  };

  const chips = [
    {
      label: intl.formatMessage(
        { id: "product.count.nb-skus" },
        { count: productPreview.numberOfSkus || 0 },
      ),
    },
    {
      label: intl.formatMessage(
        { id: "product.count.nb-assets" },
        { count: productPreview.numberOfAssets || 0 },
      ),
    },
    ...(productPreview.nbPriorityAssets
      ? [
          {
            label: intl.formatMessage(
              { id: "asset.top-priority-assets.nb" },
              { count: productPreview.nbPriorityAssets },
            ),
            color: colors.priority,
          },
        ]
      : []),
  ];

  return (
    <Paper
      key={productPreview.id}
      sx={{
        display: "flex",
        flexDirection: "row",
        maxHeight: 180,
        width: 360,
        overflow: "hidden",
      }}
    >
      <Box width={200} style={{ position: "relative" }}>
        <img
          src={productPreview.packshots[0]?.url}
          style={{ height: 160, maxWidth: 120, objectFit: "contain" }}
          alt={productPreview.name}
        />
        {productPreview.geographicScope && (
          <span style={{ position: "absolute", top: 8, left: 8 }}>
            <GeographicScopeComponent scope={productPreview.geographicScope} />
          </span>
        )}
      </Box>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p={1}
      >
        <Grid item display="flex" flexDirection="row" alignItems="center">
          <Typography
            mb={0}
            maxWidth={200}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {productPreview.name.toUpperCase()}
          </Typography>
          {productPreview.nbModifiedAssets > 0 && (
            <Dot color={colors.warning} />
          )}
        </Grid>
        <Grid item>
          <Stack
            direction="row"
            spacing={0.5}
            useFlexGap
            sx={{ flexWrap: "wrap" }}
          >
            {chips.map(({ label, color }, index) => (
              <Chip
                label={label}
                {...(color && {
                  sx: { backgroundColor: color, color: colors.white },
                })}
                key={index}
                size="small"
              />
            ))}
            {productPreview.isCancel && (
              <Chip
                label={intl.formatMessage({ id: "product.status.cancelled" })}
                size="small"
                color="error"
              />
            )}
          </Stack>
        </Grid>
        {productPreview.lastUpdate && (
          <Grid item>
            <Typography variant="caption">
              <FormattedMessage
                id="product.last-update"
                values={{
                  date: convertTimeZoneInDate(productPreview.lastUpdate),
                }}
              />
            </Typography>
          </Grid>
        )}
        <Grid
          item
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            onClick={handleClickViewDetails}
            sx={{ mr: 2, height: 34 }}
            variant="contained"
            color="secondary"
          >
            <FormattedMessage id="product.button.details" />
          </Button>
          <ProductActions
            id={productPreview.id}
            actions={[
              { action: MODAL_ACTION_TYPES.DUPLICATE_PRODUCT },
              {
                action: productPreview.isCancel
                  ? MODAL_ACTION_TYPES.UNDO_CANCEL
                  : MODAL_ACTION_TYPES.CANCEL,
              },
              { action: MODAL_ACTION_TYPES.DELETE_PRODUCT },
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductCard;
