import React, { type FC } from "react";
import type { UseFormRegister } from "react-hook-form";
import { useIntl } from "react-intl";

import { FormLabel, Grid, TextField, Typography } from "@mui/material";
import type { ProductPutForm } from "@src/types";

import type { ProductGetResponse } from "types/api/product";

interface Props {
  product: ProductGetResponse;
  canUpdateProduct?: boolean;
  register: UseFormRegister<ProductPutForm>;
}

const OwnerField: FC<{
  fieldName: keyof ProductPutForm;
  product: ProductGetResponse;
  canUpdateProduct?: boolean;
  register: UseFormRegister<ProductPutForm>;
}> = ({ fieldName, register, canUpdateProduct, product }) => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: `form.label.${fieldName}`,
    defaultMessage: fieldName,
  });

  return (
    <Grid item display="flex" flex={1}>
      {canUpdateProduct ? (
        <TextField
          label={label}
          type="text"
          variant="standard"
          size="small"
          fullWidth
          {...register(fieldName, {
            required: intl.formatMessage(
              { id: "common.rules.required" },
              { fieldLabel: label },
            ),
          })}
        />
      ) : (
        <Grid item display="flex" flexDirection="column">
          <FormLabel>{label}</FormLabel>
          <Typography>
            {fieldName in product ? (product[fieldName] as string) : ""}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const OwnersTab: FC<Props> = ({ product, canUpdateProduct, register }) => {
  const ownersFields: (keyof ProductPutForm)[] = [
    "mopOwner",
    "digitalOwner",
    "productOwner",
    "imageProductOwner",
    "imageModelShotOwner",
  ];

  return (
    <Grid container display="flex" flexDirection="row" gap={2}>
      {ownersFields.map((fieldName) => (
        <OwnerField
          key={fieldName}
          fieldName={fieldName}
          product={product}
          register={register}
          canUpdateProduct={canUpdateProduct}
        />
      ))}
    </Grid>
  );
};

export default OwnersTab;
